import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../hooks/auth/useAuth';
import useDialogContext from '../../../../hooks/dialog/useDialogContext';
import { IProduct } from '../../../../shared/interfaces/IProduct';

type SharePcfButtonProps = {
  product: IProduct;
};

const SharePcfButton = ({ product }: SharePcfButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  const { user } = useAuth();

  const onClick = () => {
    openDialog('share-pcf-dialog', { product });
  };

  return (
    <Button
      onClick={onClick}
      label={t('productDetailsPage.buttons.sharePcf')}
      icon='pi pi-arrow-right'
      iconPos='right'
      visible={user?.company?.license === 'automotive'}
      disabled={!product?.pcf}
      className='w-full md:w-auto md:min-w-max'
    />
  );
};

export default SharePcfButton;
