import {} from 'primereact/tabview';
import { useTranslation } from 'react-i18next';

import AddCompanyAccessButton from './components/add-company-access-button/AddCompanyAccessButton';
import PermissionsTable from './components/permissions-table/PermissionsTable';

export type dummyDataType = {
  companyName: string;
  bpn: string;
  dateApproved: string;
  approvedBy: string;
};

const PermissionsTab = (): JSX.Element => {
  const { t } = useTranslation();
  const dummyData: dummyDataType[] = [
    {
      companyName: 'Audi AG',
      bpn: 'BPNL493827561048',
      dateApproved: '2023-10-05T00:00:00Z',
      approvedBy: 'Ralph Wimmer',
    },
    {
      companyName: 'BMW AG',
      bpn: 'BPNL493827561049',
      dateApproved: '2023-10-06T00:00:00Z',
      approvedBy: 'John Doe',
    },
    {
      companyName: 'Mercedes-Benz AG',
      bpn: 'BPNL493827561050',
      dateApproved: '2023-10-07T00:00:00Z',
      approvedBy: 'Jane Smith',
    },
    {
      companyName: 'Volkswagen AG',
      bpn: 'BPNL493827561051',
      dateApproved: '2023-10-08T00:00:00Z',
      approvedBy: 'Alice Johnson',
    },
    {
      companyName: 'Porsche AG',
      bpn: 'BPNL493827561052',
      dateApproved: '2023-10-09T00:00:00Z',
      approvedBy: 'Bob Brown',
    },
    {
      companyName: 'Tesla Inc.',
      bpn: 'BPNL493827561053',
      dateApproved: '2023-10-10T00:00:00Z',
      approvedBy: 'Charlie Davis',
    },
    {
      companyName: 'Ford Motor Company',
      bpn: 'BPNL493827561054',
      dateApproved: '2023-10-11T00:00:00Z',
      approvedBy: 'David Evans',
    },
  ];

  return (
    <div className='flex flex-column w-full'>
      <h1 className='text-xl md:text-2xl font-medium p-0 mt-3'>
        {t('productDetailsPage.tabs.permissionsTab.title')}
      </h1>

      <PermissionsTable dummyData={dummyData} />

      {dummyData.length > 0 && (
        <div className='mt-3'>
          <AddCompanyAccessButton />
        </div>
      )}
    </div>
  );
};

export default PermissionsTab;
