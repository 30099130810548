import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import { PCFRequestStatus } from '../../shared/enums/pcf-request';
import styles from './RequestStatus.module.css';

type RequestStatusProps = {
  status: PCFRequestStatus | null;
};

export default function RequestStatus({ status }: RequestStatusProps) {
  const { t } = useTranslation();

  const determineTagClass = (status: PCFRequestStatus | null) => {
    switch (status) {
      case PCFRequestStatus.Complete:
      case PCFRequestStatus.Updated:
        return styles['complete-tag'];
      case PCFRequestStatus.Pending:
        return styles['pending-tag'];
      case PCFRequestStatus.Rejected:
        return styles['rejected-tag'];
      case PCFRequestStatus.Outdated:
        return styles['expired-tag'];
      default:
        return styles['open-tag'];
    }
  };

  return (
    <Tag rounded className={classNames('text-sm font-medium px-2 py-1', determineTagClass(status))}>
      {!status ? t(`pcfRequest.${PCFRequestStatus.Open}`) : t(`pcfRequest.${status}`)}
    </Tag>
  );
}
