import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import useDialogContext from '../../../../hooks/dialog/useDialogContext';

interface PublishPcfButtonProps {
  productId: number;
  pcfId: string;
}

const PublishPcfButton = ({ pcfId, productId }: PublishPcfButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const { openDialog } = useDialogContext();

  const onClick = () => {
    openDialog('publish-pcf-confirmation-dialog', {
      productId,
      pcfId,
    });
  };

  return (
    <Button
      onClick={onClick}
      label={t('productDetailsPage.buttons.publish')}
      icon='pi pi-arrow-right'
      iconPos='right'
      className='w-full md:w-auto md:min-w-max'
    />
  );
};

export default PublishPcfButton;
