import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PenOutlineIcon = (options: IconOptions) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        d='M9.99189 2.37651L11.03 1.33775C11.2464 1.12134 11.54 0.999756 11.846 0.999756C12.1521 0.999756 12.4456 1.12134 12.662 1.33775C12.8784 1.55416 13 1.84768 13 2.15374C13 2.45979 12.8784 2.75332 12.662 2.96973L3.81966 11.8121C3.49432 12.1372 3.09312 12.3762 2.65229 12.5075L1 12.9998L1.4923 11.3475C1.62355 10.9066 1.86253 10.5054 2.18768 10.1801L9.9925 2.37651H9.99189ZM9.99189 2.37651L11.6152 3.99987'
        stroke='#F39500'
        strokeWidth='1.25'
        strokeLinecap='round'
        strokeLinejoin='round'
        fill={options.fillColor}
      />
    </svg>
  );
};

export default PenOutlineIcon;
