import { DeclaredUnit } from '../interfaces/IProductDetails';

export const ALLOWED_INPUT_PRODUCT_MASS_UNITS = [
  DeclaredUnit.liter,
  DeclaredUnit.cubicMeter,
  DeclaredUnit.squareMeter,
  DeclaredUnit.piece,
];

export const PRODUCT_MASS_SUFFIX = 'kg';

export const PRODUCT_MASS_PER_UNIT_MAPPING = {
  [DeclaredUnit.kilogram]: 1,
  [DeclaredUnit.kilowattHour]: 0,
  [DeclaredUnit.megajoule]: 0,
  [DeclaredUnit.tonKilometer]: 0,
};

export const getProductMassPerUnit = (
  declaredUnit?: DeclaredUnit,
  productMass?: number,
): number => {
  if (!declaredUnit) {
    return 0;
  }

  if (ALLOWED_INPUT_PRODUCT_MASS_UNITS.includes(declaredUnit)) {
    return productMass ?? 0;
  }

  return (
    PRODUCT_MASS_PER_UNIT_MAPPING[declaredUnit as keyof typeof PRODUCT_MASS_PER_UNIT_MAPPING] ?? 0
  );
};
