import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import useDialogContext from '../../../../../../../../hooks/dialog/useDialogContext';

type CreatePcfButtonProps = {
  disabled?: boolean;
};

const CreatePcfButton = ({ disabled }: CreatePcfButtonProps): JSX.Element => {
  const { t } = useTranslation();
  // Getting product id from URL
  const { productId } = useParams() as { productId: string };
  const { openDialog } = useDialogContext();

  const onClick = () => {
    openDialog('create-pcf-dialog', { productId });
  };

  return (
    <Button
      className='max-w-fit flex'
      disabled={disabled}
      label={t('productDetailsPage.tabs.pcfTab.buttons.createPcfButton')}
      onClick={onClick}
    />
  );
};

export default CreatePcfButton;
