import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../../hooks/auth/useAuth';
import { Locale } from '../../../../../i18n';
import { SUPPORT_EMAIL } from '../../../../../shared/constants/emails.constant';
import generateEmailBody from './generateEmailBody';

const ContactSupport = () => {
  const { user } = useAuth();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const onContactSupportClick = () => {
    const emailSubject = t('emails.contactSupport.subject');
    let emailBody = generateEmailBody(user, language as Locale);

    // Encode the email body for the mailto link
    emailBody = encodeURIComponent(emailBody);

    // Create the mailto link with the recipient, subject, and body
    const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${emailSubject}&body=${emailBody}`;

    // Open the user's default email client with the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <a
      id='contactSupportButton'
      className='topbar-action rounded-circle mr-3 text-white'
      onClick={onContactSupportClick}
    >
      <i className='cb cb-icon-contact white-icon' />
      <Tooltip
        target='#contactSupportButton'
        content={t('global.contactSupport')}
        position='bottom'
      />
    </a>
  );
};

export default ContactSupport;
