import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ComponentsFilledIcon from '../../../../assets/icons/ComponentsFilled';
import ComponentsOutlineIcon from '../../../../assets/icons/ComponentsOutline';
import ProductsFilledIcon from '../../../../assets/icons/ProductsFilled';
import ProductsOutlineIcon from '../../../../assets/icons/ProductsOutline';
import RequestsFilledIcon from '../../../../assets/icons/RequestsFilled';
import RequestsOutlineIcon from '../../../../assets/icons/RequestsOutline';
import Logo, { LogoType } from '../../../../components/logo';
import ContactSupport from './contact-support/ContactSupport';
import styles from './Header.module.css';
import HeaderLink from './header-link/HeaderLink';
import MobileMenu from './mobile-menu/MobileMenu';
import MobileMenuOverlay from './mobile-menu/MobileMenuOverlay';
import Notifications from './notifications/Notifications';
import UserButton from './user-button/UserButton';

const Header = () => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  const onMenuClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (overlayPanelRef.current) {
      overlayPanelRef.current?.toggle(event);
    }
  };

  const onLocationChange = () => {
    overlayPanelRef.current?.hide();
  };

  const onOverlayPanelShow = () => {
    return setIsOverlayVisible(true);
  };

  const onOverlayPanelHide = () => {
    return setIsOverlayVisible(false);
  };

  useEffect(() => {
    onLocationChange();
  }, [location]);

  return (
    <>
      <div
        className={classNames(
          'flex justify-content-between align-items-center h-4rem sm:px-6',
          styles.header,
        )}
      >
        <Logo className={styles['logo']} type={LogoType.COMBINED} white />
        <div className='h-full hidden lg:flex'>
          <HeaderLink
            href='/products'
            label={t('global.productMenu')}
            icon={ProductsOutlineIcon}
            activeIcon={ProductsFilledIcon}
          />
          <HeaderLink
            href='/components'
            label={t('global.componentsMenu')}
            icon={ComponentsOutlineIcon}
            activeIcon={ComponentsFilledIcon}
          />
          <HeaderLink
            href='/requests'
            label={t('global.requestMenu')}
            icon={RequestsOutlineIcon}
            activeIcon={RequestsFilledIcon}
          />
        </div>
        <div className='flex'>
          <Notifications />
          <MobileMenu isOpen={isOverlayVisible} onClick={onMenuClick} />
          <ContactSupport />
          <UserButton />
        </div>
      </div>
      <OverlayPanel
        ref={overlayPanelRef}
        className='w-full shadow-2 mt-2'
        pt={{
          content: {
            className: 'flex flex-column align-items-center',
          },
        }}
        onShow={onOverlayPanelShow}
        onHide={onOverlayPanelHide}
      >
        <MobileMenuOverlay />
      </OverlayPanel>
    </>
  );
};

export default Header;
