import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import EmptyComponentsImg from '../../../assets/images/empty-components.svg';
import EmptyScreen from '../../../components/empty-screen/EmptyScreen';
import RequestStatus from '../../../components/request-status/RequestStatus';
import { PcfRequestsParams, useGetPcfRequests } from '../../../hooks/api/request-pcf';
import { Locale } from '../../../i18n';
import { AppRoutes } from '../../../shared/enums/app-routes';
import { mapComponentRegionToKey } from '../../../shared/enums/component-region';
import { PCFRequestStatus } from '../../../shared/enums/pcf-request';
import { RequestType } from '../../../shared/enums/request-type';
import { parseDateWithoutTime } from '../../../shared/helpers/parse-date';
import { requestRegionSortFunction } from '../../../shared/helpers/requestRegionSortFunction';
import { requestsProductIdSortFunction } from '../../../shared/helpers/requests-product-id-sort-function';
import { IRequest } from '../../../shared/interfaces/IRequest';
import styles from './PcfRequestsTable.module.css';
import PcfRequestsTableHeader from './PcfRequestsTableHeader';
import ProductId from './ProductId';
import ProductName from './ProductName';

export interface RequestFilters {
  requestTypeFilter: string[];
  searchStr: string;
}

const PcfRequestsTable = (): JSX.Element => {
  // Search params (filters) from the URL
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    i18n: { language },
  } = useTranslation();
  const { t } = useTranslation();

  const [requestFilters, setRequestFilters] = useState<RequestFilters>({
    requestTypeFilter: ['pcf,external_pcf, additional_product'],
    searchStr: searchParams.get('search') || '',
  });

  // Listen to URL string parameter changes, and update the filter values
  useEffect(() => {
    setRequestFilters({
      ...requestFilters,
      searchStr: searchParams.get('search') || '',
    });
  }, [searchParams]);

  // Function for updating the filter values in the URL
  const updateRequestFilters = (newFilters: RequestFilters) => {
    let newSearchParams;

    if (newFilters.searchStr) {
      newSearchParams = { search: newFilters.searchStr };
    }

    // Update URL parameters
    setSearchParams(newSearchParams);
  };

  // Prepare the filter parameters for fetching requests, utilizing memoization
  const filterParams = useMemo(() => {
    const paramsValue: PcfRequestsParams = {};

    if (requestFilters.searchStr) {
      paramsValue['search'] = requestFilters.searchStr;
    }

    return paramsValue;
  }, [requestFilters]);

  // RQ Query to get pcf requests
  const { data, isLoading, isError, error } = useGetPcfRequests(filterParams);

  if (isError) {
    return (
      <div className='text-error'>
        {t('pcfRequest.failedToGetRequests')} {error.toString()}
      </div>
    );
  }

  if (!isLoading && data?.length === 0 && Object.keys(filterParams).length === 0) {
    return (
      <EmptyScreen imageSrc={EmptyComponentsImg}>
        <p>{t('pcfRequest.emptyScreen.title')}</p>
        <p>
          {t('pcfRequest.emptyScreen.label1')}{' '}
          <Link to={AppRoutes.Products} className='text-primary underline'>
            {t('pcfRequest.emptyScreen.label2')}
          </Link>{' '}
          {t('pcfRequest.emptyScreen.label3')}
        </p>
      </EmptyScreen>
    );
  }

  const dateRequestedTemplate = (rowData: IRequest): React.ReactNode => {
    return parseDateWithoutTime(rowData.date_created ?? '', language as Locale);
  };

  const requestedByTemplate = (rowData: IRequest): React.ReactNode => {
    return `${rowData.user.first_name} ${rowData.user.last_name}`;
  };

  const statusTemplate = (rowData: IRequest): React.ReactNode => {
    return <RequestStatus status={rowData.status} />;
  };

  const productNameTemplate = (rowData: IRequest): React.ReactNode => {
    let productName;
    let requestedProductName;

    if (rowData.request_type === RequestType.AdditionalComponentPCF) {
      if (rowData.status !== PCFRequestStatus.Complete) {
        productName = rowData?.other_request_data?.component_name;
      } else {
        // If it is additional product request and is already answered, show product id as main product id and additional product id below it
        productName = rowData?.product?.product_name;
        requestedProductName = rowData?.other_request_data?.component_name;
      }
    } else {
      // If it is not additional product request, show product id as main product id
      productName = rowData?.product?.product_name;
    }

    return <ProductName productName={productName} requestedProductName={requestedProductName} />;
  };

  const productIdTemplate = (rowData: IRequest): React.ReactNode => {
    let productId;
    let requestedProductId;

    if (rowData.request_type === RequestType.AdditionalComponentPCF) {
      if (rowData.status !== PCFRequestStatus.Complete) {
        // If it is additional product request and hasn't been answered yet, then show additional product id as main product id
        productId = rowData?.other_request_data?.component_id;
      } else {
        // If it is additional product request and is already answered, show product id as main product id and additional product id below it
        productId = rowData?.product?.product_id?.toString();
        requestedProductId = rowData?.other_request_data?.component_id;
      }
    } else {
      // If it is not additional product request, show product id as main product id
      productId = rowData?.product?.product_id?.toString();
    }

    return <ProductId productId={productId} requestedProductId={requestedProductId} />;
  };

  const regionTemplate = (request: IRequest) => {
    if (!request.region) return;

    return t(`componentsPage.regionNames.${mapComponentRegionToKey(request.region)}`);
  };

  return (
    <div className={styles['pcf-requests-table']}>
      <DataTable
        value={data}
        loading={isLoading}
        scrollable
        sortField='date_created'
        sortOrder={-1}
        header={
          <PcfRequestsTableHeader
            requestFilters={requestFilters}
            updateRequestFilters={updateRequestFilters}
          />
        }
        pt={{
          wrapper: {
            className: styles['table-wrapper'],
          },
        }}
        className={styles['pcf-requests-table']}
      >
        <Column
          field='product.product_name'
          sortable
          header={t('pcfRequest.productName')}
          style={{ minWidth: '8rem', maxWidth: '16rem' }}
          frozen
          body={productNameTemplate}
          className={styles['product-colummn']}
        />
        <Column
          field='product.product_id'
          sortable
          sortFunction={requestsProductIdSortFunction}
          header={t('pcfRequest.productId')}
          style={{ minWidth: '8rem', maxWidth: '12rem' }}
          body={productIdTemplate}
          className={styles['product-colummn']}
        />
        <Column
          field='region'
          sortable
          sortFunction={requestRegionSortFunction}
          header={t('pcfRequest.region')}
          style={{ minWidth: '10rem' }}
          body={regionTemplate}
        />
        <Column
          body={dateRequestedTemplate}
          sortable
          sortField='date_created'
          header={t('pcfRequest.dateRequested')}
        />
        <Column
          body={requestedByTemplate}
          sortable
          sortField='user.first_name'
          header={t('pcfRequest.requestedBy')}
        />
        <Column body={statusTemplate} sortable sortField='status' header={t('pcfRequest.status')} />
      </DataTable>
    </div>
  );
};

export default PcfRequestsTable;
