import 'normalize.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/layout/prime-css/primereact.min.css';
import './assets/layout/prime-css/theme-pacific-light.css';
import './assets/layout/prime-css/layout-light.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { sessionStorage } from 'aws-amplify/utils';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';

import App from './App';
import { amplifyLibraryOptions, amplifyResourceConfigs } from './config/amplify';
import { AuthProvider } from './hooks/auth/useAuth';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      refetchOnWindowFocus: false,
    },
  },
});

Amplify.configure(amplifyResourceConfigs, amplifyLibraryOptions);

if (window.sessionStorage.length) {
  // If there is a session storage, set it to the cognito user pools token provider
  // So that when user refreshes the page it won't be logged out as per by default amplify checks local storage
  // And when it can't find the session in the local storage, it logs out the user
  // Thus, this configuration is important
  cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </I18nextProvider>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
