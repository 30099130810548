import dayjs from 'dayjs';

import translationsDE from '../../assets/translations/de.json';
import translationsEN from '../../assets/translations/en.json';
import { Locale } from '../../i18n';

const translationsMap = {
  [Locale.en]: translationsEN,
  [Locale.de]: translationsDE,
};

export const parseDateWithoutTime = (date?: string, language: Locale = Locale.en): string => {
  return dayjs(date).format(translationsMap[language].translation.global.dateFormat);
};
