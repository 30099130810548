/* eslint-disable*/

import { TreeNode } from 'primereact/treenode';

export const CountryCodeToLabelMap: Record<string, string> = {
  DZ: 'Algeria',
  EG: 'Egypt',
  LY: 'Libya',
  MA: 'Morocco',
  SD: 'Sudan (the)',
  TN: 'Tunisia',
  EH: 'Western Sahara',
  IO: 'British Indian Ocean Territory (the)',
  BI: 'Burundi',
  KM: 'Comoros (the)',
  DJ: 'Djibouti',
  ER: 'Eritrea',
  ET: 'Ethiopia',
  TF: 'French Southern Territories (the)',
  KE: 'Kenya',
  MG: 'Madagascar',
  MW: 'Malawi',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MZ: 'Mozambique',
  RE: 'Réunion',
  RW: 'Rwanda',
  SC: 'Seychelles',
  SO: 'Somalia',
  SS: 'South Sudan',
  UG: 'Uganda',
  TZ: 'Tanzania, the United Republic of',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
  AO: 'Angola',
  CM: 'Cameroon',
  CF: 'Central African Republic (the)',
  TD: 'Chad',
  CG: 'Congo (the)',
  CD: 'Congo (the Democratic Republic of the)',
  GQ: 'Equatorial Guinea',
  GA: 'Gabon',
  ST: 'Sao Tome and Principe',
  BW: 'Botswana',
  SZ: 'Eswatini',
  LS: 'Lesotho',
  NA: 'Namibia',
  ZA: 'South Africa',
  BJ: 'Benin',
  BF: 'Burkina Faso',
  CV: 'Cabo Verde',
  CI: "Côte d'Ivoire",
  GM: 'Gambia (the)',
  GH: 'Ghana',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  LR: 'Liberia',
  ML: 'Mali',
  MR: 'Mauritania',
  NE: 'Niger (the)',
  NG: 'Nigeria',
  SH: 'Saint Helena',
  SN: 'Senegal',
  SL: 'Sierra Leone',
  TG: 'Togo',
  AI: 'Anguilla',
  AG: 'Antigua and Barbuda',
  AW: 'Aruba',
  BS: 'Bahamas (the)',
  BB: 'Barbados',
  BQ: 'Bonaire',
  VG: 'Virgin Islands (British)',
  KY: 'Cayman Islands (the)',
  CU: 'Cuba',
  CW: 'Curaçao',
  DM: 'Dominica',
  DO: 'Dominican Republic (the)',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  HT: 'Haiti',
  JM: 'Jamaica',
  MQ: 'Martinique',
  MS: 'Montserrat',
  PR: 'Puerto Rico',
  BL: 'Saint Barthélemy',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin (French part)',
  VC: 'Saint Vincent and the Grenadines',
  SX: 'Sint Maarten (Dutch part)',
  TT: 'Trinidad and Tobago',
  TC: 'Turks and Caicos Islands (the)',
  VI: 'Virgin Islands (U.S.)',
  BZ: 'Belize',
  CR: 'Costa Rica',
  SV: 'El Salvador',
  GT: 'Guatemala',
  HN: 'Honduras',
  MX: 'Mexico',
  NI: 'Nicaragua',
  PA: 'Panama',
  AR: 'Argentina',
  BO: 'Bolivia (Plurinational State of)',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  CL: 'Chile',
  CO: 'Colombia',
  EC: 'Ecuador',
  FK: 'Falkland Islands (the)',
  GF: 'French Guiana',
  GY: 'Guyana',
  PY: 'Paraguay',
  PE: 'Peru',
  GS: 'South Georgia and the South Sandwich Islands',
  SR: 'Suriname',
  UY: 'Uruguay',
  VE: 'Venezuela (Bolivarian Republic of)',
  BM: 'Bermuda',
  CA: 'Canada',
  GL: 'Greenland',
  PM: 'Saint Pierre and Miquelon',
  US: 'United States of America (the)',
  KZ: 'Kazakhstan',
  KG: 'Kyrgyzstan',
  TJ: 'Tajikistan',
  TM: 'Turkmenistan',
  UZ: 'Uzbekistan',
  CN: 'China',
  HK: 'Hong Kong',
  MO: 'Macao',
  TW: 'Taiwan (Province of China)',
  KP: "Korea (the Democratic People's Republic of)",
  JP: 'Japan',
  MN: 'Mongolia',
  KR: 'Korea (the Republic of)',
  BN: 'Brunei Darussalam',
  KH: 'Cambodia',
  ID: 'Indonesia',
  LA: "Lao People's Democratic Republic (the)",
  MY: 'Malaysia',
  MM: 'Myanmar',
  PH: 'Philippines (the)',
  SG: 'Singapore',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  VN: 'Viet Nam',
  AF: 'Afghanistan',
  BD: 'Bangladesh',
  BT: 'Bhutan',
  IN: 'India',
  IR: 'Iran (Islamic Republic of)',
  MV: 'Maldives',
  NP: 'Nepal',
  PK: 'Pakistan',
  LK: 'Sri Lanka',
  AM: 'Armenia',
  AZ: 'Azerbaijan',
  BH: 'Bahrain',
  CY: 'Cyprus',
  GE: 'Georgia',
  IQ: 'Iraq',
  IL: 'Israel',
  JO: 'Jordan',
  KW: 'Kuwait',
  LB: 'Lebanon',
  OM: 'Oman',
  QA: 'Qatar',
  SA: 'Saudi Arabia',
  PS: 'Palestine, State of',
  SY: 'Syrian Arab Republic (the)',
  TR: 'Türkiye',
  AE: 'United Arab Emirates (the)',
  YE: 'Yemen',
  BY: 'Belarus',
  BG: 'Bulgaria',
  CZ: 'Czechia',
  HU: 'Hungary',
  PL: 'Poland',
  MD: 'Moldova (the Republic of)',
  RO: 'Romania',
  RU: 'Russian Federation (the)',
  SK: 'Slovakia',
  UA: 'Ukraine',
  AX: 'Åland Islands',
  DK: 'Denmark',
  EE: 'Estonia',
  FO: 'Faroe Islands (the)',
  FI: 'Finland',
  GG: 'Guernsey',
  IS: 'Iceland',
  IE: 'Ireland',
  IM: 'Isle of Man',
  JE: 'Jersey',
  LV: 'Latvia',
  LT: 'Lithuania',
  NO: 'Norway',
  SJ: 'Svalbard',
  SE: 'Sweden',
  GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
  AL: 'Albania',
  AD: 'Andorra',
  BA: 'Bosnia and Herzegovina',
  HR: 'Croatia',
  GI: 'Gibraltar',
  GR: 'Greece',
  VA: 'Holy See (the)',
  IT: 'Italy',
  MT: 'Malta',
  ME: 'Montenegro',
  MK: 'North Macedonia',
  PT: 'Portugal',
  SM: 'San Marino',
  RS: 'Serbia',
  SI: 'Slovenia',
  ES: 'Spain',
  AT: 'Austria',
  BE: 'Belgium',
  FR: 'France',
  DE: 'Germany',
  LI: 'Liechtenstein',
  LU: 'Luxembourg',
  MC: 'Monaco',
  NL: 'Netherlands (the)',
  CH: 'Switzerland',
  AU: 'Australia',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands (the)',
  HM: 'Heard Island and McDonald Islands',
  NZ: 'New Zealand',
  NF: 'Norfolk Island',
  FJ: 'Fiji',
  NC: 'New Caledonia',
  PG: 'Papua New Guinea',
  SB: 'Solomon Islands',
  VU: 'Vanuatu',
  GU: 'Guam',
  KI: 'Kiribati',
  MH: 'Marshall Islands (the)',
  FM: 'Micronesia (Federated States of)',
  NR: 'Nauru',
  MP: 'Northern Mariana Islands (the)',
  PW: 'Palau',
  UM: 'United States Minor Outlying Islands (the)',
  AS: 'American Samoa',
  CK: 'Cook Islands (the)',
  PF: 'French Polynesia',
  NU: 'Niue',
  PN: 'Pitcairn',
  WS: 'Samoa',
  TK: 'Tokelau',
  TO: 'Tonga',
  TV: 'Tuvalu',
  WF: 'Wallis and Futuna',
};

export const CountryLabelToCodeMap: Record<string, string> = {
  Algeria: 'DZ',
  Egypt: 'EG',
  Libya: 'LY',
  Morocco: 'MA',
  'Sudan (the)': 'SD',
  Tunisia: 'TN',
  'Western Sahara': 'EH',
  'British Indian Ocean Territory (the)': 'IO',
  Burundi: 'BI',
  'Comoros (the)': 'KM',
  Djibouti: 'DJ',
  Eritrea: 'ER',
  Ethiopia: 'ET',
  'French Southern Territories (the)': 'TF',
  Kenya: 'KE',
  Madagascar: 'MG',
  Malawi: 'MW',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Mozambique: 'MZ',
  Réunion: 'RE',
  Rwanda: 'RW',
  Seychelles: 'SC',
  Somalia: 'SO',
  'South Sudan': 'SS',
  Uganda: 'UG',
  'Tanzania, the United Republic of': 'TZ',
  Zambia: 'ZM',
  Zimbabwe: 'ZW',
  Angola: 'AO',
  Cameroon: 'CM',
  'Central African Republic (the)': 'CF',
  Chad: 'TD',
  'Congo (the)': 'CG',
  'Congo (the Democratic Republic of the)': 'CD',
  'Equatorial Guinea': 'GQ',
  Gabon: 'GA',
  'Sao Tome and Principe': 'ST',
  Botswana: 'BW',
  Eswatini: 'SZ',
  Lesotho: 'LS',
  Namibia: 'NA',
  'South Africa': 'ZA',
  Benin: 'BJ',
  'Burkina Faso': 'BF',
  'Cabo Verde': 'CV',
  "Côte d'Ivoire": 'CI',
  'Gambia (the)': 'GM',
  Ghana: 'GH',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Liberia: 'LR',
  Mali: 'ML',
  Mauritania: 'MR',
  'Niger (the)': 'NE',
  Nigeria: 'NG',
  'Saint Helena': 'SH',
  Senegal: 'SN',
  'Sierra Leone': 'SL',
  Togo: 'TG',
  Anguilla: 'AI',
  'Antigua and Barbuda': 'AG',
  Aruba: 'AW',
  'Bahamas (the)': 'BS',
  Barbados: 'BB',
  Bonaire: 'BQ',
  'Virgin Islands (British)': 'VG',
  'Cayman Islands (the)': 'KY',
  Cuba: 'CU',
  Curaçao: 'CW',
  Dominica: 'DM',
  'Dominican Republic (the)': 'DO',
  Grenada: 'GD',
  Guadeloupe: 'GP',
  Haiti: 'HT',
  Jamaica: 'JM',
  Martinique: 'MQ',
  Montserrat: 'MS',
  'Puerto Rico': 'PR',
  'Saint Barthélemy': 'BL',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Martin (French part)': 'MF',
  'Saint Vincent and the Grenadines': 'VC',
  'Sint Maarten (Dutch part)': 'SX',
  'Trinidad and Tobago': 'TT',
  'Turks and Caicos Islands (the)': 'TC',
  'Virgin Islands (U.S.)': 'VI',
  Belize: 'BZ',
  'Costa Rica': 'CR',
  'El Salvador': 'SV',
  Guatemala: 'GT',
  Honduras: 'HN',
  Mexico: 'MX',
  Nicaragua: 'NI',
  Panama: 'PA',
  Argentina: 'AR',
  'Bolivia (Plurinational State of)': 'BO',
  'Bouvet Island': 'BV',
  Brazil: 'BR',
  Chile: 'CL',
  Colombia: 'CO',
  Ecuador: 'EC',
  'Falkland Islands (the)': 'FK',
  'French Guiana': 'GF',
  Guyana: 'GY',
  Paraguay: 'PY',
  Peru: 'PE',
  'South Georgia and the South Sandwich Islands': 'GS',
  Suriname: 'SR',
  Uruguay: 'UY',
  'Venezuela (Bolivarian Republic of)': 'VE',
  Bermuda: 'BM',
  Canada: 'CA',
  Greenland: 'GL',
  'Saint Pierre and Miquelon': 'PM',
  'United States of America (the)': 'US',
  Kazakhstan: 'KZ',
  Kyrgyzstan: 'KG',
  Tajikistan: 'TJ',
  Turkmenistan: 'TM',
  Uzbekistan: 'UZ',
  China: 'CN',
  'Hong Kong': 'HK',
  Macao: 'MO',
  'Taiwan (Province of China)': 'TW',
  "Korea (the Democratic People's Republic of)": 'KP',
  Japan: 'JP',
  Mongolia: 'MN',
  'Korea (the Republic of)': 'KR',
  'Brunei Darussalam': 'BN',
  Cambodia: 'KH',
  Indonesia: 'ID',
  "Lao People's Democratic Republic (the)": 'LA',
  Malaysia: 'MY',
  Myanmar: 'MM',
  'Philippines (the)': 'PH',
  Singapore: 'SG',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  'Viet Nam': 'VN',
  Afghanistan: 'AF',
  Bangladesh: 'BD',
  Bhutan: 'BT',
  India: 'IN',
  'Iran (Islamic Republic of)': 'IR',
  Maldives: 'MV',
  Nepal: 'NP',
  Pakistan: 'PK',
  'Sri Lanka': 'LK',
  Armenia: 'AM',
  Azerbaijan: 'AZ',
  Bahrain: 'BH',
  Cyprus: 'CY',
  Georgia: 'GE',
  Iraq: 'IQ',
  Israel: 'IL',
  Jordan: 'JO',
  Kuwait: 'KW',
  Lebanon: 'LB',
  Oman: 'OM',
  Qatar: 'QA',
  'Saudi Arabia': 'SA',
  'Palestine, State of': 'PS',
  'Syrian Arab Republic (the)': 'SY',
  Türkiye: 'TR',
  'United Arab Emirates (the)': 'AE',
  Yemen: 'YE',
  Belarus: 'BY',
  Bulgaria: 'BG',
  Czechia: 'CZ',
  Hungary: 'HU',
  Poland: 'PL',
  'Moldova (the Republic of)': 'MD',
  Romania: 'RO',
  'Russian Federation (the)': 'RU',
  Slovakia: 'SK',
  Ukraine: 'UA',
  'Åland Islands': 'AX',
  Denmark: 'DK',
  Estonia: 'EE',
  'Faroe Islands (the)': 'FO',
  Finland: 'FI',
  Guernsey: 'GG',
  Iceland: 'IS',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Jersey: 'JE',
  Latvia: 'LV',
  Lithuania: 'LT',
  Norway: 'NO',
  Svalbard: 'SJ',
  Sweden: 'SE',
  'United Kingdom of Great Britain and Northern Ireland (the)': 'GB',
  Albania: 'AL',
  Andorra: 'AD',
  'Bosnia and Herzegovina': 'BA',
  Croatia: 'HR',
  Gibraltar: 'GI',
  Greece: 'GR',
  'Holy See (the)': 'VA',
  Italy: 'IT',
  Malta: 'MT',
  Montenegro: 'ME',
  'North Macedonia': 'MK',
  Portugal: 'PT',
  'San Marino': 'SM',
  Serbia: 'RS',
  Slovenia: 'SI',
  Spain: 'ES',
  Austria: 'AT',
  Belgium: 'BE',
  France: 'FR',
  Germany: 'DE',
  Liechtenstein: 'LI',
  Luxembourg: 'LU',
  Monaco: 'MC',
  'Netherlands (the)': 'NL',
  Switzerland: 'CH',
  Australia: 'AU',
  'Christmas Island': 'CX',
  'Cocos (Keeling) Islands (the)': 'CC',
  'Heard Island and McDonald Islands': 'HM',
  'New Zealand': 'NZ',
  'Norfolk Island': 'NF',
  Fiji: 'FJ',
  'New Caledonia': 'NC',
  'Papua New Guinea': 'PG',
  'Solomon Islands': 'SB',
  Vanuatu: 'VU',
  Guam: 'GU',
  Kiribati: 'KI',
  'Marshall Islands (the)': 'MH',
  'Micronesia (Federated States of)': 'FM',
  Nauru: 'NR',
  'Northern Mariana Islands (the)': 'MP',
  Palau: 'PW',
  'United States Minor Outlying Islands (the)': 'UM',
  'American Samoa': 'AS',
  'Cook Islands (the)': 'CK',
  'French Polynesia': 'PF',
  Niue: 'NU',
  Pitcairn: 'PN',
  Samoa: 'WS',
  Tokelau: 'TK',
  Tonga: 'TO',
  Tuvalu: 'TV',
  'Wallis and Futuna': 'WF',
};

export const RegionCodeToLabelMap: Record<string, string> = {
  africa: 'Africa',
  northern_africa: 'Northern Africa',
  sub_saharan_africa: 'Sub-Saharan Africa',
  americas: 'Americas',
  latin_america_and_the_caribbean: 'Latin America and the Caribbean',
  northern_america: 'Northern America',
  asia: 'Asia',
  central_asia: 'Central Asia',
  eastern_asia: 'Eastern Asia',
  south_eastern_asia: 'South-eastern Asia',
  southern_asia: 'Southern Asia',
  western_asia: 'Western Asia',
  europe: 'Europe',
  eastern_europe: 'Eastern Europe',
  northern_europe: 'Northern Europe',
  southern_europe: 'Southern Europe',
  western_europe: 'Western Europe',
  oceania: 'Oceania',
  australia_and_new_zealand: 'Australia and New Zealand',
  melanesia: 'Melanesia',
  micronesia: 'Micronesia',
  polynesia: 'Polynesia',
  aq: 'Antarctica',
  several: 'Several',
  global: 'Global',
};

export const RegionLabelToCodeMap: Record<string, string> = {
  Africa: 'africa',
  'Northern Africa': 'northern_africa',
  'Sub-Saharan Africa': 'sub_saharan_africa',
  Americas: 'americas',
  'Latin America and the Caribbean': 'latin_america_and_the_caribbean',
  'Northern America': 'northern_america',
  Asia: 'asia',
  'Central Asia': 'central_asia',
  'Eastern Asia': 'eastern_asia',
  'South-eastern Asia': 'south_eastern_asia',
  'Southern Asia': 'southern_asia',
  'Western Asia': 'western_asia',
  Europe: 'europe',
  'Eastern Europe': 'eastern_europe',
  'Northern Europe': 'northern_europe',
  'Southern Europe': 'southern_europe',
  'Western Europe': 'western_europe',
  Oceania: 'oceania',
  'Australia and New Zealand': 'australia_and_new_zealand',
  Melanesia: 'melanesia',
  Micronesia: 'micronesia',
  Polynesia: 'polynesia',
  Antarctica: 'aq',
  Several: 'several',
  Global: 'global',
};

export const CountryCodeToRegionCodeMap: Record<string, string> = {
  DZ: 'northern_africa',
  EG: 'northern_africa',
  LY: 'northern_africa',
  MA: 'northern_africa',
  SD: 'northern_africa',
  TN: 'northern_africa',
  EH: 'northern_africa',
  IO: 'sub_saharan_africa',
  BI: 'sub_saharan_africa',
  KM: 'sub_saharan_africa',
  DJ: 'sub_saharan_africa',
  ER: 'sub_saharan_africa',
  ET: 'sub_saharan_africa',
  TF: 'sub_saharan_africa',
  KE: 'sub_saharan_africa',
  MG: 'sub_saharan_africa',
  MW: 'sub_saharan_africa',
  MU: 'sub_saharan_africa',
  YT: 'sub_saharan_africa',
  MZ: 'sub_saharan_africa',
  RE: 'sub_saharan_africa',
  RW: 'sub_saharan_africa',
  SC: 'sub_saharan_africa',
  SO: 'sub_saharan_africa',
  SS: 'sub_saharan_africa',
  UG: 'sub_saharan_africa',
  TZ: 'sub_saharan_africa',
  ZM: 'sub_saharan_africa',
  ZW: 'sub_saharan_africa',
  AO: 'sub_saharan_africa',
  CM: 'sub_saharan_africa',
  CF: 'sub_saharan_africa',
  TD: 'sub_saharan_africa',
  CG: 'sub_saharan_africa',
  CD: 'sub_saharan_africa',
  GQ: 'sub_saharan_africa',
  GA: 'sub_saharan_africa',
  ST: 'sub_saharan_africa',
  BW: 'sub_saharan_africa',
  SZ: 'sub_saharan_africa',
  LS: 'sub_saharan_africa',
  NA: 'sub_saharan_africa',
  ZA: 'sub_saharan_africa',
  BJ: 'sub_saharan_africa',
  BF: 'sub_saharan_africa',
  CV: 'sub_saharan_africa',
  CI: 'sub_saharan_africa',
  GM: 'sub_saharan_africa',
  GH: 'sub_saharan_africa',
  GN: 'sub_saharan_africa',
  GW: 'sub_saharan_africa',
  LR: 'sub_saharan_africa',
  ML: 'sub_saharan_africa',
  MR: 'sub_saharan_africa',
  NE: 'sub_saharan_africa',
  NG: 'sub_saharan_africa',
  SH: 'sub_saharan_africa',
  SN: 'sub_saharan_africa',
  SL: 'sub_saharan_africa',
  TG: 'sub_saharan_africa',
  AI: 'latin_america_and_the_caribbean',
  AG: 'latin_america_and_the_caribbean',
  AW: 'latin_america_and_the_caribbean',
  BS: 'latin_america_and_the_caribbean',
  BB: 'latin_america_and_the_caribbean',
  BQ: 'latin_america_and_the_caribbean',
  VG: 'latin_america_and_the_caribbean',
  KY: 'latin_america_and_the_caribbean',
  CU: 'latin_america_and_the_caribbean',
  CW: 'latin_america_and_the_caribbean',
  DM: 'latin_america_and_the_caribbean',
  DO: 'latin_america_and_the_caribbean',
  GD: 'latin_america_and_the_caribbean',
  GP: 'latin_america_and_the_caribbean',
  HT: 'latin_america_and_the_caribbean',
  JM: 'latin_america_and_the_caribbean',
  MQ: 'latin_america_and_the_caribbean',
  MS: 'latin_america_and_the_caribbean',
  PR: 'latin_america_and_the_caribbean',
  BL: 'latin_america_and_the_caribbean',
  KN: 'latin_america_and_the_caribbean',
  LC: 'latin_america_and_the_caribbean',
  MF: 'latin_america_and_the_caribbean',
  VC: 'latin_america_and_the_caribbean',
  SX: 'latin_america_and_the_caribbean',
  TT: 'latin_america_and_the_caribbean',
  TC: 'latin_america_and_the_caribbean',
  VI: 'latin_america_and_the_caribbean',
  BZ: 'latin_america_and_the_caribbean',
  CR: 'latin_america_and_the_caribbean',
  SV: 'latin_america_and_the_caribbean',
  GT: 'latin_america_and_the_caribbean',
  HN: 'latin_america_and_the_caribbean',
  MX: 'latin_america_and_the_caribbean',
  NI: 'latin_america_and_the_caribbean',
  PA: 'latin_america_and_the_caribbean',
  AR: 'latin_america_and_the_caribbean',
  BO: 'latin_america_and_the_caribbean',
  BV: 'latin_america_and_the_caribbean',
  BR: 'latin_america_and_the_caribbean',
  CL: 'latin_america_and_the_caribbean',
  CO: 'latin_america_and_the_caribbean',
  EC: 'latin_america_and_the_caribbean',
  FK: 'latin_america_and_the_caribbean',
  GF: 'latin_america_and_the_caribbean',
  GY: 'latin_america_and_the_caribbean',
  PY: 'latin_america_and_the_caribbean',
  PE: 'latin_america_and_the_caribbean',
  GS: 'latin_america_and_the_caribbean',
  SR: 'latin_america_and_the_caribbean',
  UY: 'latin_america_and_the_caribbean',
  VE: 'latin_america_and_the_caribbean',
  BM: 'northern_america',
  CA: 'northern_america',
  GL: 'northern_america',
  PM: 'northern_america',
  US: 'northern_america',
  KZ: 'central_asia',
  KG: 'central_asia',
  TJ: 'central_asia',
  TM: 'central_asia',
  UZ: 'central_asia',
  CN: 'eastern_asia',
  HK: 'eastern_asia',
  MO: 'eastern_asia',
  TW: 'eastern_asia',
  KP: 'eastern_asia',
  JP: 'eastern_asia',
  MN: 'eastern_asia',
  KR: 'eastern_asia',
  BN: 'south_eastern_asia',
  KH: 'south_eastern_asia',
  ID: 'south_eastern_asia',
  LA: 'south_eastern_asia',
  MY: 'south_eastern_asia',
  MM: 'south_eastern_asia',
  PH: 'south_eastern_asia',
  SG: 'south_eastern_asia',
  TH: 'south_eastern_asia',
  TL: 'south_eastern_asia',
  VN: 'south_eastern_asia',
  AF: 'southern_asia',
  BD: 'southern_asia',
  BT: 'southern_asia',
  IN: 'southern_asia',
  IR: 'southern_asia',
  MV: 'southern_asia',
  NP: 'southern_asia',
  PK: 'southern_asia',
  LK: 'southern_asia',
  AM: 'western_asia',
  AZ: 'western_asia',
  BH: 'western_asia',
  CY: 'western_asia',
  GE: 'western_asia',
  IQ: 'western_asia',
  IL: 'western_asia',
  JO: 'western_asia',
  KW: 'western_asia',
  LB: 'western_asia',
  OM: 'western_asia',
  QA: 'western_asia',
  SA: 'western_asia',
  PS: 'western_asia',
  SY: 'western_asia',
  TR: 'western_asia',
  AE: 'western_asia',
  YE: 'western_asia',
  BY: 'eastern_europe',
  BG: 'eastern_europe',
  CZ: 'eastern_europe',
  HU: 'eastern_europe',
  PL: 'eastern_europe',
  MD: 'eastern_europe',
  RO: 'eastern_europe',
  RU: 'eastern_europe',
  SK: 'eastern_europe',
  UA: 'eastern_europe',
  AX: 'northern_europe',
  DK: 'northern_europe',
  EE: 'northern_europe',
  FO: 'northern_europe',
  FI: 'northern_europe',
  GG: 'northern_europe',
  IS: 'northern_europe',
  IE: 'northern_europe',
  IM: 'northern_europe',
  JE: 'northern_europe',
  LV: 'northern_europe',
  LT: 'northern_europe',
  NO: 'northern_europe',
  SJ: 'northern_europe',
  SE: 'northern_europe',
  GB: 'northern_europe',
  AL: 'southern_europe',
  AD: 'southern_europe',
  BA: 'southern_europe',
  HR: 'southern_europe',
  GI: 'southern_europe',
  GR: 'southern_europe',
  VA: 'southern_europe',
  IT: 'southern_europe',
  MT: 'southern_europe',
  ME: 'southern_europe',
  MK: 'southern_europe',
  PT: 'southern_europe',
  SM: 'southern_europe',
  RS: 'southern_europe',
  SI: 'southern_europe',
  ES: 'southern_europe',
  AT: 'western_europe',
  BE: 'western_europe',
  FR: 'western_europe',
  DE: 'western_europe',
  LI: 'western_europe',
  LU: 'western_europe',
  MC: 'western_europe',
  NL: 'western_europe',
  CH: 'western_europe',
  AU: 'australia_and_new_zealand',
  CX: 'australia_and_new_zealand',
  CC: 'australia_and_new_zealand',
  HM: 'australia_and_new_zealand',
  NZ: 'australia_and_new_zealand',
  NF: 'australia_and_new_zealand',
  FJ: 'melanesia',
  NC: 'melanesia',
  PG: 'melanesia',
  SB: 'melanesia',
  VU: 'melanesia',
  GU: 'micronesia',
  KI: 'micronesia',
  MH: 'micronesia',
  FM: 'micronesia',
  NR: 'micronesia',
  MP: 'micronesia',
  PW: 'micronesia',
  UM: 'micronesia',
  AS: 'polynesia',
  CK: 'polynesia',
  PF: 'polynesia',
  NU: 'polynesia',
  PN: 'polynesia',
  WS: 'polynesia',
  TK: 'polynesia',
  TO: 'polynesia',
  TV: 'polynesia',
  WF: 'polynesia',
};

/**
 * Traverses a tree structure using Depth-First Search (DFS) in an in-order manner
 * to find the closest parent node of a node with the specified key.
 *
 * @param nodes - The array of tree nodes to traverse.
 * @param parent - The parent node of the current node being traversed.
 * @param targetKey - The key of the target node whose closest parent is to be found.
 * @returns The closest parent node of the node with the specified key, or null if not found.
 */
const traverseInOrderDFSWithParent = (
  nodes: TreeNode[],
  parent: TreeNode | null,
  targetKey: string,
): TreeNode | null => {
  for (const node of nodes) {
    if (node.key === targetKey) {
      return parent;
    }

    if (node.children) {
      const result = traverseInOrderDFSWithParent(node.children, node, targetKey);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

/**
 * Traverses a tree of nodes in an in-order depth-first search manner to find a node with the specified key.
 *
 * @param nodes - An array of tree nodes to traverse.
 * @param targetKey - The key of the target node to find.
 * @returns The node with the specified key if found, otherwise null.
 */
const traverseInOrderDFS = (nodes: TreeNode[], targetKey: string): TreeNode | null => {
  for (const node of nodes) {
    if (node.key === targetKey) {
      return node;
    }

    if (node.children) {
      const result = traverseInOrderDFS(node.children, targetKey);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

/**
 * Finds a node in a tree structure by its key.
 *
 * @param countries - The array of tree nodes to search within.
 * @param key - The key of the node to find.
 * @returns The tree node with the specified key, or null if not found.
 */
export const findNodeByKey = (countries: TreeNode[], key: string): TreeNode | null => {
  return traverseInOrderDFS(countries, key);
};

export const extractFullyCheckedNodes = (selectedKeys: {
  [key: string]: { checked: boolean; partiallyChecked: boolean };
}): { [key: string]: { checked: boolean; partiallyChecked: boolean } } => {
  const fullyCheckedNodes = {} as {
    [key: string]: { checked: boolean; partiallyChecked: boolean };
  };

  Object.keys(selectedKeys).forEach((key) => {
    if (selectedKeys[key].checked) {
      fullyCheckedNodes[key] = selectedKeys[key];
    }
  });

  return fullyCheckedNodes;
};
