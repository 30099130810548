import { lazy } from 'react';

export const SidebarMapper = {
  'edit-pcf-general-details-sidebar': lazy(() => {
    return import('./sidebars/edit-pcf-general-details-sidebar/EditPcfGeneralDetailsSidebar');
  }),
  'edit-emissions-details-sidebar': lazy(() => {
    return import('./sidebars/edit-emissions-details-sidebar/EditEmissionsDetailsSidebar');
  }),
  'edit-additional-details-sidebar': lazy(() => {
    return import('./sidebars/edit-additional-details-sidebar/EditAdditionalDetailsSidebar');
  }),
};

export type SidebarType = keyof typeof SidebarMapper;
