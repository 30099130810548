/* eslint-disable */

import { useTranslation } from 'react-i18next';
import useSidebarContext from '../../../../hooks/sidebar/useSidebarContext';
import { parseDateWithoutTime } from '../../../../shared/helpers/parse-date';
import PenOutlineIcon from '../../../../assets/icons/PenOutline';
import PenFilledIcon from '../../../../assets/icons/PenFilled';
import CustomIcon from '../../../../shared/components/custom-icon/CustomIcon';
import { Skeleton } from 'primereact/skeleton';
import { useGetFootprintById } from '../../../../hooks/api/footprint';
import { useParams } from 'react-router-dom';
import { textifyDeclaredUnit } from '../../../../shared/helpers/textify-numerical-footprint-values-with-their-units';
import ProductMass from './components/product-mass/ProductMass';
import { FootPrintStatus } from '../../../../shared/interfaces/IFootprint';
import { Locale } from '../../../../i18n';

const PcfGeneralDetailsSection = (): JSX.Element => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { openSidebar } = useSidebarContext();

  // Get footprintId from URL
  const { footprintId } = useParams() as { footprintId: string };

  // Getting the footprint details
  const { data: footprintDetails, isLoading } = useGetFootprintById(footprintId, {
    enabled: !!footprintId,
    retry: 0,
  });

  return (
    <div className='flex flex-column gap-2 w-full'>
      {/* Pcf General Details */}
      <div className='flex flex-row gap-3 align-items-center pb-3'>
        <h4 className='m-0 p-0 font-semibold'>
          {t('footprintDetailsPage.sections.pcfGeneralDetailsSection.title')}
        </h4>
        {footprintDetails && footprintDetails.status === FootPrintStatus.DRAFT && (
          <CustomIcon
            icon={PenOutlineIcon}
            hoverIcon={PenFilledIcon}
            showTextOnHover
            hoverText={t('global.edit')}
            hoverTextColor='primary'
            className='cursor-pointer flex justify-content-center align-items-center'
            onClick={() => {
              openSidebar('edit-pcf-general-details-sidebar', {
                position: 'right',
                footprintDetails: footprintDetails,
              });
            }}
          />
        )}
      </div>

      <div className='flex grid grid-nogutter md:flex-row w-full gap-4 md:gap-0'>
        {/* Pcf Id */}
        <div className='col-12 md:col-4 flex flex-column gap-2 md:pr-4'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.pcfGeneralDetailsSection.pcfId')}{' '}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className='font-light text-black-alpha-60'>
              {footprintDetails?.id ?? t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Cpc Code */}
        <div className='col-12 md:col-2 flex flex-column gap-2 md:pr-4'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.pcfGeneralDetailsSection.cpcCode')}{' '}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className='font-light text-black-alpha-60'>
              {footprintDetails?.product_code_cpc ?? t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Date Created */}
        <div className='col-12 md:col-2 flex flex-column gap-2 md:pr-4'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.pcfGeneralDetailsSection.dateCreated')}{' '}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className='font-light text-black-alpha-60'>
              {parseDateWithoutTime(footprintDetails?.date_created, language as Locale)}
            </span>
          )}
        </div>

        {/* Declared Unit */}
        <div className='col-12 md:col-2 flex flex-column gap-2 md:pr-4'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.pcfGeneralDetailsSection.declaredUnit')}{' '}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <span className='font-light text-black-alpha-60'>
              {footprintDetails?.pcf?.unitary_product_amount != null &&
              footprintDetails?.pcf?.declared_unit != null
                ? footprintDetails?.pcf.unitary_product_amount +
                  ' ' +
                  textifyDeclaredUnit(footprintDetails?.pcf.declared_unit)
                : t('global.notSpecified')}
            </span>
          )}
        </div>

        {/* Product Mass */}
        <div className='col-12 md:col-2 flex flex-column gap-2'>
          <span className='font-medium'>
            {t('footprintDetailsPage.sections.pcfGeneralDetailsSection.productMass')}{' '}
          </span>
          {isLoading ? (
            <Skeleton height='16px' />
          ) : (
            <ProductMass
              productMass={footprintDetails?.pcf?.product_mass_per_declared_unit}
              declaredUnit={footprintDetails?.pcf?.declared_unit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PcfGeneralDetailsSection;
