import { Skeleton } from 'primereact/skeleton';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { useGetFootprintById } from '../../../../hooks/api/footprint';
import { FootPrintStatus } from '../../../../shared/interfaces/IFootprint';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import ProductInplaceEdit from '../product-inplace-edit/ProductInplaceEdit';
import PublishPcfButton from '../publish-pcf-button/PublishPcfButton';
import SharePcfButton from '../share-pcf-button/SharePcfButton';

type ProductNameSectionProps = {
  product: IProduct;
  isLoading: boolean;
};

const ProductNameSection = ({
  product,
  isLoading = false,
}: ProductNameSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const { footprintId } = useParams() as { footprintId: string };

  // Getting the footprint details
  const { data: footprintDetails } = useGetFootprintById(footprintId, {
    enabled: !!footprintId,
    retry: 0,
  });

  const [currentTabName, setCurrentTabName] = useState<string | null>(null);

  useEffect(() => {
    // Get current tab name from URL, to render proper buttons
    setCurrentTabName(location.pathname.split('/')?.[3] || null);
  }, [location]);

  const canShowPublishButton = useMemo(() => {
    // When current tab is 'pcf' and footprint is in Draft state, need to show publish button
    return currentTabName === 'pcf' && footprintDetails?.status === FootPrintStatus.DRAFT;
  }, [currentTabName, footprintDetails]);

  if (isLoading) {
    return <Skeleton height='46.63px' className='w-12 md:w-6 lg:w-4' />;
  }

  return (
    <div className='flex flex-column md:flex-row w-full align-items-center justify-content-between gap-2 md:gap-5'>
      <ProductInplaceEdit
        fieldId='product_name'
        value={product?.product_name}
        textClassname='text-3xl p-0 m-0'
        displayClassname='font-bold'
        undefinedText={t('global.notSpecified')}
        className='w-full'
      />

      {currentTabName === 'product-details' && <SharePcfButton product={product} />}
      {canShowPublishButton && <PublishPcfButton productId={product.id} pcfId={footprintId} />}
    </div>
  );
};

export default ProductNameSection;
