import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import {
  CountryCodeToLabelMap,
  CountryCodeToRegionCodeMap,
  RegionCodeToLabelMap,
} from '../../shared/helpers/country-region-helpers';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IProduct } from '../../shared/interfaces/IProduct';

export type GetProductsProps = {
  country?: string;
  region?: string;
  search?: string;
};

type AddProductProps = {
  product_id: string;
  product_name: string;
  region: string;
  country?: string;
  description?: string;
};

type AddProductResponse = {
  id: number;
};

type UpdateProductProps = {
  productId: string;
  product_id?: string;
  product_name?: string;
  location?: string;
  region?: string | null;
  country?: string | null;
  description?: string;
};

type UpdateProductResponse = Omit<IProduct, 'pcf'>;

const amplifyApiService = new AmplifyApiService();

export const useGetProductById = (
  productId: string,
  options: UseQueryOptions<IProduct, Error> = {},
): UseQueryResult<IProduct, Error> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `product/${productId}`,
    options: {},
  };

  const query = useQuery<IProduct, Error>({
    queryKey: ['product', productId],
    queryFn: async (): Promise<IProduct> => {
      const response = await amplifyApiService.get<IProduct>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

export const useGetProducts = (
  filters: GetProductsProps = {},
  options: UseQueryOptions<IProduct[], Error> = {},
): UseQueryResult<IProduct[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'product',
    options: {
      queryParams: filters,
    },
  };

  const query = useQuery<IProduct[], Error>({
    queryKey: ['products', filters],
    queryFn: async (): Promise<IProduct[]> => {
      const response = await amplifyApiService.get<IProduct[]>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

export const useAddProduct = (
  options: UseMutationOptions<AddProductResponse, Error, AddProductProps> = {},
): UseMutationResult<AddProductResponse, Error, AddProductProps> => {
  const mutation = useMutation<AddProductResponse, Error, AddProductProps>({
    mutationFn: async (body: AddProductProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: 'product',
        options: { body },
      };

      return await amplifyApiService.post<AddProductProps, AddProductResponse>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useUpdateProductDetails = (
  options: UseMutationOptions<UpdateProductResponse, Error, UpdateProductProps> = {},
): UseMutationResult<UpdateProductResponse, Error, UpdateProductProps> => {
  const mutation = useMutation<UpdateProductResponse, Error, UpdateProductProps>({
    mutationFn: async ({ productId, ...body }: UpdateProductProps) => {
      const { location, ...requestBody } = body;

      // Handle location and region values in the request body
      if (location) {
        if (CountryCodeToLabelMap[location]) {
          requestBody.country = location;
          requestBody.region = CountryCodeToRegionCodeMap[location];
        } else if (RegionCodeToLabelMap[location]) {
          requestBody.region = location;
          requestBody.country = null;
        }
      }

      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `product/${productId}`,
        options: {
          body: requestBody,
        },
      };

      return await amplifyApiService.put<Partial<UpdateProductProps>, UpdateProductResponse>(
        amplifyApiConfig,
      );
    },
    ...options,
  });

  return mutation;
};
