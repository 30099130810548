import { classNames } from 'primereact/utils';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useInplaceEdit } from '../../contexts/component-details-page-inplace-edit/ComponentDetailsPageInplaceEditContext';
import { useGetComponentDetails } from '../../hooks/api/components';
import ComponentBreadCrumb from './components/component-breadcrumb/ComponentBreadCrumb';
import CarbonFootprintDetailsSection from './components/sections/carbon-footprint-details-section/CarbonFootprintDetails';
import GeneralDetailsSection from './components/sections/general-details-section/GeneralDetailsSection';
import ProductCarbonFootprintSection from './components/sections/product-carbon-footprint-section/ProductCarbonFootprintSection';
import ProductNameSection from './components/sections/product-name-section/ProductNameSection';

const ComponentDetailsPage = (): JSX.Element => {
  // Get productId from URL
  const { componentId } = useParams() as { componentId: string };

  // Destructure the inplace edit context
  const { setComponentId: setContextComponentId } = useInplaceEdit();

  // Fetch JSON data from Pacific API
  const {
    isLoading,
    isError,
    data: componentDetails,
  } = useGetComponentDetails(componentId, { retry: 1 });

  // Set the componentId in the inplace edit context
  useEffect(() => {
    setContextComponentId(componentId);
  }, [componentId, setContextComponentId]);

  if (
    isError ||
    (!isLoading && !(componentDetails || Object.keys(componentDetails).length === 0))
  ) {
    return <Navigate to='/404' replace={true} />;
  }

  return (
    <div className={classNames('p-5 flex flex-column gap-3')}>
      {/* Breadcrumb */}
      <ComponentBreadCrumb isLoading={isLoading} componentName={componentDetails?.productName} />

      {/* Product Name Section*/}
      <ProductNameSection
        isLoading={isLoading}
        mainProductName={componentDetails?.productName}
        ownProductName={componentDetails?.own_product_name}
      />

      {/* Top Section */}
      <div className='grid m-0 gap-3'>
        <GeneralDetailsSection
          componentDetails={componentDetails}
          isLoading={isLoading}
          className='col-12 lg:col'
        />
        <ProductCarbonFootprintSection
          componentDetails={componentDetails}
          isLoading={isLoading}
          className='col-12 lg:col'
        />
      </div>

      {/* Bottom Section */}
      <div className='grid m-0 '>
        <CarbonFootprintDetailsSection
          componentDetais={componentDetails}
          isLoading={isLoading}
          className='col'
        />
      </div>
    </div>
  );
};

export default ComponentDetailsPage;
