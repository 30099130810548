import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './ProductBreadCrumb.module.css';

type ProductBreadCrumbProps = {
  isLoading?: boolean;
  productName?: string;
};

const ProductBreadCrumb = ({ isLoading, productName }: ProductBreadCrumbProps): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const home: MenuItem = {
    label: t('productDetailsPage.breadcrumb.products'),
    className: styles['home-item'],
    command: (e: MenuItemCommandEvent) => {
      e.originalEvent.preventDefault();

      const previousPath = (location.state?.from as string) ?? '';

      if (previousPath.includes('/products')) {
        navigate(location.state?.from);
      } else {
        navigate('/products');
      }
    },
  };
  const items = [{ label: productName, disabled: true }];

  if (isLoading) {
    return (
      <div>
        <Skeleton height='1.5rem' className='w-8 md:w-2' />
      </div>
    );
  }

  return <BreadCrumb model={items} home={home} className='bg-transparent border-none p-0' />;
};

export default ProductBreadCrumb;
