import { useTranslation } from 'react-i18next';

import {
  getSuffixWithCO2e,
  getSuffixWithKgC,
} from '../../helpers/textify-numerical-footprint-values-with-their-units';
import { DeclaredUnit } from '../../interfaces/IProductDetails';

type EmissionValueProps = {
  amount?: number | null;
  declaredUnit?: DeclaredUnit;
  suffixType?: 'kgC' | 'kgCO2e';
  amountTextClassname?: string;
  unitTextClassname?: string;
  abbreviateUnit?: boolean;
};

const EmissionValue = (props: EmissionValueProps): JSX.Element => {
  const {
    amount,
    declaredUnit,
    suffixType,
    amountTextClassname = 'font-medium',
    unitTextClassname = 'font-light text-black-alpha-60',
    abbreviateUnit = false,
  } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation();

  if (amount == null || declaredUnit == null) {
    return <span className={unitTextClassname}>{t('global.notSpecified')}</span>;
  }

  let suffix = '';

  if (suffixType === 'kgC') {
    suffix = getSuffixWithKgC(declaredUnit, abbreviateUnit);
  } else if (suffixType === 'kgCO2e') {
    suffix = getSuffixWithCO2e(declaredUnit, abbreviateUnit);
  }

  return (
    <div className='flex gap-1'>
      <span className={amountTextClassname}>{amount.toLocaleString(language)}</span>
      <span className={unitTextClassname}>{suffix}</span>
    </div>
  );
};

export default EmissionValue;
