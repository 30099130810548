import {
  del as apiDel,
  get as apiGet,
  patch as apiPatch,
  post as apiPost,
  put as apiPut,
} from 'aws-amplify/api';
import { signOut } from 'aws-amplify/auth';

import { AmplifyApiResponseBody, PacificApiResponse } from '../config/amplify';
import IAmplifyApiConfig from '../shared/interfaces/IAmplifyApiConfig';

type AmplifyFnType =
  | typeof apiGet
  | typeof apiPost
  | typeof apiPut
  | typeof apiPatch
  | typeof apiDel;

type AmplifyFnPropsType =
  | Parameters<typeof apiGet>[0]
  | Parameters<typeof apiPost>[0]
  | Parameters<typeof apiPut>[0]
  | Parameters<typeof apiPatch>[0]
  | Parameters<typeof apiDel>[0];

class AmplifyApiService {
  private async handleRequest<T>(fn: AmplifyFnType, props: AmplifyFnPropsType): Promise<T> {
    try {
      const response = await fn(props).response;

      // Check if the response has the "body" field (Response of Amplify's 'del' method doesn't have it)
      if (!('body' in response)) {
        return '' as T;
      }

      const data = (
        (await (response.body as AmplifyApiResponseBody).json()) as unknown as PacificApiResponse<T>
      ).body.data;

      return data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async get<T>({ apiName, path, options }: IAmplifyApiConfig<unknown>): Promise<T> {
    return this.handleRequest<T>(apiGet, { apiName, path, options });
  }

  async post<T, R>({ apiName, path, options }: IAmplifyApiConfig<T>): Promise<R> {
    return this.handleRequest<R>(apiPost, { apiName, path, options });
  }

  async patch<T, R>({ apiName, path, options }: IAmplifyApiConfig<T>): Promise<R> {
    return this.handleRequest<R>(apiPatch, { apiName, path, options });
  }

  async put<T, R>({ apiName, path, options }: IAmplifyApiConfig<T>): Promise<R> {
    return this.handleRequest<R>(apiPut, { apiName, path, options });
  }

  async delete<T, R>({ apiName, path, options }: IAmplifyApiConfig<T>): Promise<R> {
    return this.handleRequest<R>(apiDel, { apiName, path, options });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async handleError(error: any): Promise<never> {
    if (error.response) {
      if (error.response.status === 403) {
        await signOut({ global: true });
        window.location.href = '/login';
      }
      throw new Error(error.message);
    }

    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error(error);
  }
}

export default AmplifyApiService;
