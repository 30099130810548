import {
  SUFFIX_CARBON_CONTENT_NUMERATOR,
  SUFFIX_EMISSION_NUMERATOR,
} from '../constants/products.constant';
import { DeclaredUnit, DeclaredUnitToPlainSymbolsMap } from '../interfaces/IProductDetails';

// Function to convert declared unit which directly comes from the backend, into plain text
// e.g.
//  'kilogram' => 'kilogram'
//  'cubic_meter' => 'cubic meter'
//  'ton_kilometer' => 'ton kilometer'
export const textifyDeclaredUnit = (unit?: DeclaredUnit): string => {
  return unit?.replace(/_/g, ' ') ?? '';
};

export const getSuffixWithKgC = (unit?: DeclaredUnit, abbreviateUnit = false): string => {
  if (!unit) {
    return '';
  }

  if (abbreviateUnit) {
    return ` ${SUFFIX_CARBON_CONTENT_NUMERATOR}/${DeclaredUnitToPlainSymbolsMap[unit]}`;
  } else {
    return ` ${SUFFIX_CARBON_CONTENT_NUMERATOR}/${textifyDeclaredUnit(unit)}`;
  }
};

export const getSuffixWithCO2e = (unit?: DeclaredUnit, abbreviateUnit = false): string => {
  if (!unit) {
    return '';
  }

  if (abbreviateUnit) {
    return ` ${SUFFIX_EMISSION_NUMERATOR}/${DeclaredUnitToPlainSymbolsMap[unit]}`;
  } else {
    return ` ${SUFFIX_EMISSION_NUMERATOR}/${textifyDeclaredUnit(unit)}`;
  }
};
