import { SidebarProps } from 'primereact/sidebar';
import { ComponentType, LazyExoticComponent, Suspense } from 'react';

import useSidebarContext from '../../hooks/sidebar/useSidebarContext';
import { SidebarMapper } from './SidebarMapper';

const SidebarManager = (): JSX.Element | null => {
  const { activeSidebar, sidebarProps, closeSidebar } = useSidebarContext();

  if (activeSidebar === null || activeSidebar === undefined) return null;

  // Get the lazy-loaded component from SidebarMapper
  const LazySidebarComponent = SidebarMapper[
    activeSidebar as keyof typeof SidebarMapper
  ] as LazyExoticComponent<ComponentType<SidebarProps>>;

  return (
    <Suspense fallback={null}>
      <LazySidebarComponent {...sidebarProps} onHide={closeSidebar} closeOnEscape />
    </Suspense>
  );
};

export default SidebarManager;
