/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { ICompany } from '../../shared/interfaces/ICompany';
import { IUser, UserStatus } from '../../shared/interfaces/IUser';
import { UserRole } from '../../shared/types/User';

type IUserDetails = Pick<IUser, 'id' | 'first_name' | 'last_name' | 'email'> & {
  company: Pick<
    ICompany,
    'id' | 'company_id' | 'company_name' | 'edc_configuration_status' | 'license'
  >;
  language: string;
};

type AddUserMutationProps = {
  email: string;
  first_name: string;
  last_name: string;
  company_id: number;
  language: string;
  role: UserRole;
};
type AddUserResponse = { id: number };

type UpdateUserNameMutationProps = {
  userId: number;
  first_name: string;
  last_name: string;
};
type UpdateUserNameResponse = { id: number };

type GetInvitationLinkMutationProps = {
  userId: number;
};
export type GetInvitationLinkResponse = {
  inviteLink: string;
};

type AssignAdminRoleMutationProps = {
  userId: number;
};

type UpdateUserStatusMutationProps = {
  userId: number;
  status: UserStatus;
};

type CancelInvitationMutationProps = {
  userId: number;
};

const amplifyApiService = new AmplifyApiService();

export const useGetUser = (): UseQueryResult<IUserDetails> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'user/me',
    options: {},
  };

  const query = useQuery<IUserDetails, Error>({
    queryKey: ['user', 'me'],
    queryFn: async (): Promise<IUserDetails> => {
      const response: IUserDetails = await amplifyApiService.get<IUserDetails>(amplifyApiConfig);

      return response;
    },
    enabled: false,
  });

  return query;
};

export const useAddUser = (
  options: UseMutationOptions<AddUserResponse, Error, AddUserMutationProps> = {},
): UseMutationResult<AddUserResponse, Error, AddUserMutationProps> => {
  const mutation = useMutation<AddUserResponse, Error, AddUserMutationProps>({
    mutationFn: async (body: AddUserMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: 'user',
        options: { body },
      };

      return await amplifyApiService.post<AddUserMutationProps, AddUserResponse>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useUpdateUserName = (
  options: UseMutationOptions<UpdateUserNameResponse, Error, UpdateUserNameMutationProps> = {},
): UseMutationResult<UpdateUserNameResponse, Error, UpdateUserNameMutationProps> => {
  const mutation = useMutation<UpdateUserNameResponse, Error, UpdateUserNameMutationProps>({
    mutationFn: async ({ userId, first_name, last_name }: UpdateUserNameMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        options: {
          body: {
            first_name,
            last_name,
          },
        },
      };

      return await amplifyApiService.put<
        { first_name: string; last_name: string },
        UpdateUserNameResponse
      >(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useListUsers = (
  options: UseQueryOptions<IUser[], Error> = {},
): UseQueryResult<IUser[]> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'user',
    options: {},
  };

  const query = useQuery<IUser[], Error>({
    queryKey: ['list-company-users'],
    queryFn: async (): Promise<IUser[]> => {
      const response = await amplifyApiService.get<{ users: IUser[] }>({
        ...amplifyApiConfig,
      });

      return response.users;
    },
    ...options,
  });

  return query;
};

export const useGetInvitationLink = (
  options: UseMutationOptions<
    GetInvitationLinkResponse,
    Error,
    GetInvitationLinkMutationProps
  > = {},
): UseMutationResult<GetInvitationLinkResponse, Error, GetInvitationLinkMutationProps> => {
  const mutation = useMutation<GetInvitationLinkResponse, Error, GetInvitationLinkMutationProps>({
    mutationFn: async ({ userId }: GetInvitationLinkMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        options: {
          queryParams: {
            action: 'get-invite-link',
          },
        },
      };
      const response = await amplifyApiService.put<undefined, GetInvitationLinkResponse>(
        amplifyApiConfig,
      );

      return response;
    },
    ...options,
  });

  return mutation;
};

export const useAssignAdminRole = (
  options: UseMutationOptions<void, Error, AssignAdminRoleMutationProps> = {},
): UseMutationResult<void, Error, AssignAdminRoleMutationProps> => {
  const mutation = useMutation<void, Error, AssignAdminRoleMutationProps>({
    mutationFn: async ({ userId }: AssignAdminRoleMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        options: { body: { role: 'company-admin' } },
      };

      return await amplifyApiService.put<{ role: UserRole }, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useUpdateUserStatus = (
  options: UseMutationOptions<void, Error, UpdateUserStatusMutationProps> = {},
): UseMutationResult<void, Error, UpdateUserStatusMutationProps> => {
  const mutation = useMutation<void, Error, UpdateUserStatusMutationProps>({
    mutationFn: async ({ userId, status }: UpdateUserStatusMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        options: { body: { status } },
      };

      return await amplifyApiService.put<{ status: UserStatus }, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useCancelInvitation = (
  options: UseMutationOptions<void, Error, CancelInvitationMutationProps> = {},
): UseMutationResult<void, Error, CancelInvitationMutationProps> => {
  const mutation = useMutation<void, Error, CancelInvitationMutationProps>({
    mutationFn: async ({ userId }: CancelInvitationMutationProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `user/${userId}`,
        options: {},
      };

      return await amplifyApiService.delete<undefined, void>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};
/* eslint-enable camelcase */
