import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type GoBackToPcfListButtonProps = {
  productId: string;
};

const GoBackToPcfListButton = ({ productId }: GoBackToPcfListButtonProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <Button
      className='justify-content-center text-sm p-0 mt-2'
      text
      icon='pi pi-arrow-circle-left'
      onClick={() => {
        return navigate(`/products/${productId}/pcf`);
      }}
      label={t('productDetailsPage.tabs.pcfTab.buttons.goBackButton')}
    />
  );
};

export default GoBackToPcfListButton;
