/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { INotification } from '../../shared/interfaces/INotification';
import { IPagination } from '../../shared/interfaces/IPagination';

const amplifyApiService = new AmplifyApiService();

type NotificationsResponse = { notifications: INotification[]; pagination: IPagination };

export type NotificationsParams = {
  read?: boolean;
  size?: number;
  skip?: number;
};

export const useListNotifications = (
  filters: NotificationsParams = {},
  options: UseQueryOptions<NotificationsResponse, Error> = {},
): UseQueryResult<NotificationsResponse> => {
  const queryParams: { [key: string]: string } = {};

  if (filters.read != null) {
    queryParams.read = filters.read.toString();
  }

  if (filters.size != null) {
    queryParams.size = filters.size.toString();
  }

  if (filters.skip != null) {
    queryParams.skip = filters.skip.toString();
  }

  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'notification',
    options: {
      queryParams,
    },
  };

  const query = useQuery<NotificationsResponse, Error>({
    queryKey: ['notifications', filters],
    queryFn: async (): Promise<NotificationsResponse> => {
      const response = await amplifyApiService.get<NotificationsResponse>(amplifyApiConfig);

      return response;
    },
    cacheTime: 0, // Doesn't cache any data
    ...options,
  });

  return query;
};

export const useUpdateNotification = (
  options: UseMutationOptions<void, Error, number> = {},
): UseMutationResult<void, Error, number> => {
  const mutation = useMutation<void, Error, number>({
    mutationFn: async (id: number) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `notification/${id}`,
        options: { body: { read: true } },
      };

      await amplifyApiService.put<
        { read: boolean },
        {
          message: string;
          data: boolean;
        }
      >(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};
