import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import EmissionValue from '../../../../../shared/components/emission-value/EmissionValue';
import { textifyDeclaredUnit } from '../../../../../shared/helpers/textify-numerical-footprint-values-with-their-units';
import { IComponentDetails } from '../../../../../shared/interfaces/IProductDetails';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';

interface ProductCarbonFootprintSectionProps extends ComponentProps<'div'> {
  componentDetails?: IComponentDetails;
  isLoading?: boolean;
}

const ProductCarbonFootprintSection = (props: ProductCarbonFootprintSectionProps): JSX.Element => {
  const { componentDetails, isLoading, ...rest } = props;

  const { t } = useTranslation();

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>
        {t('componentDetailsPage.sections.productCarbonFootprintSection.productCarbonFootprint')}
      </h5>

      {/* Declared Unit */}
      <InfoCardRow
        label={t('componentDetailsPage.sections.productCarbonFootprintSection.declaredUnit')}
      >
        <span className='font-light text-black-alpha-60'>
          {componentDetails?.pcf?.unitaryProductAmount != null &&
          componentDetails?.pcf?.declaredUnit != null
            ? componentDetails?.pcf.unitaryProductAmount +
              ' ' +
              textifyDeclaredUnit(componentDetails?.pcf.declaredUnit)
            : t('global.notSpecified')}
        </span>
      </InfoCardRow>

      {/* Carbon Content */}
      <h6>{t('componentDetailsPage.sections.productCarbonFootprintSection.carbonContent')}</h6>

      {/* Fossil Carbon Content */}
      <InfoCardRow
        label={t('componentDetailsPage.sections.productCarbonFootprintSection.fossilCarbonContent')}
      >
        <EmissionValue
          amount={componentDetails?.pcf.extWBCSD_fossilCarbonContent}
          declaredUnit={componentDetails?.pcf.declaredUnit}
          suffixType='kgC'
        />
      </InfoCardRow>

      {/* Biogenic Carbon Content */}
      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.productCarbonFootprintSection.biogenicCarbonContent',
        )}
      >
        <EmissionValue
          amount={componentDetails?.pcf.carbonContentBiogenic}
          declaredUnit={componentDetails?.pcf.declaredUnit}
          suffixType='kgC'
        />
      </InfoCardRow>

      {/* Total */}
      <h6>{t('componentDetailsPage.sections.productCarbonFootprintSection.total')}</h6>

      {/* PCF (excl. Biogenic emissions and removals) */}
      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.productCarbonFootprintSection.pcfExclBiogenicEmissionsAndRemovals',
        )}
        textColor='text-primary'
      >
        <EmissionValue
          amount={componentDetails?.pcf.pcfExcludingBiogenic}
          declaredUnit={componentDetails?.pcf.declaredUnit}
          suffixType='kgCO2e'
          unitTextClassname='font-light text-primary'
        />
      </InfoCardRow>

      {/* PCF (incl. Biogenic emissions and removals) */}
      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.productCarbonFootprintSection.pcfInclBiogenicEmissionsAndRemovals',
        )}
      >
        <EmissionValue
          amount={componentDetails?.pcf.pcfIncludingBiogenic}
          declaredUnit={componentDetails?.pcf.declaredUnit}
          suffixType='kgCO2e'
        />
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(ProductCarbonFootprintSection);
