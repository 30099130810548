import { useTranslation } from 'react-i18next';

import {
  ProductOrSectorSpecificRule,
  ProductOrSectorSpecificRuleOperator,
} from '../../interfaces/IFootprint';

type ProductOrSectorSpecificRulesProps = {
  rules: ProductOrSectorSpecificRule[];
};

// Component to display the product or sector specific rules as a list of rows, each row is a text
const ProductOrSectorSpecificRules = (props: ProductOrSectorSpecificRulesProps): JSX.Element => {
  const { rules } = props;

  const { t } = useTranslation();

  const rulesList = rules.map((rule) => {
    const operatorName =
      rule.operator === ProductOrSectorSpecificRuleOperator.OTHER
        ? rule.other_operator_name
        : rule.operator;

    const ruleName = rule.product_or_sector_specific_rules[0].rule_name;

    return `${operatorName}: ${ruleName}`;
  });

  if (!rulesList.length) {
    return <span className='text-black-alpha-60'>{t('global.notSpecified')}</span>;
  }

  return (
    <div className='flex flex-column gap-2 w-full text-black-alpha-60'>
      {rulesList.map((rule) => {
        return (
          <div key={rule} className='w-full align-items-center'>
            <span>{rule}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ProductOrSectorSpecificRules;
