import { useContext } from 'react';

import SidebarContext from '../../contexts/slidebar/SidebarContext';
import { ISidebarContext } from '../../shared/interfaces/ISidebarContext';

const useSidebarContext = (): ISidebarContext => {
  const state = useContext(SidebarContext);

  if (state === undefined) {
    throw new Error('useSidebarContext must be used within a NotificationProvider');
  }

  return { ...state };
};

export default useSidebarContext;
