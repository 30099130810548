/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { fetchAuthSession } from 'aws-amplify/auth';

export interface PacificApiResponse<T> {
  statusCode: number;
  body: {
    message: string;
    data: T;
  };
  headers: Headers;
}

// Directly taken from Amplify REST Api Documentation
export interface AmplifyApiResponseBody {
  blob(): Promise<Blob>;
  json(): Promise<DocumentType>;
  text(): Promise<string>;
}

const authConfig = {
  Cognito: {
    region: process.env.REACT_APP_COGNITO_REGION || '',
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || '',
    userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
    userPoolEndpoint: process.env.REACT_APP_COGNITO_ENDPOINT || '',
    authenticationFlowType: process.env.REACT_APP_COGNITO_AUTHENTICATIONFLOWTYPE || '',
  },
};

const apiConfig = {
  REST: {
    PacificApi: {
      endpoint: process.env.REACT_APP_AMPLIFY_API_ENDPOINT || '',
      region: process.env.REACT_APP_COGNITO_REGION || '',
    },
  },
};

export const amplifyResourceConfigs = {
  Auth: authConfig,
  API: apiConfig,
};

// Authorization configuration for Amplify API
export const amplifyLibraryOptions = {
  API: {
    REST: {
      headers: async () => {
        return { Authorization: (await fetchAuthSession()).tokens?.idToken?.toString() || '' };
      },
    },
  },
};
