import { ComponentProps, createContext } from 'react';

import { SidebarMapper } from '../../components/sidebar-manager/SidebarMapper';
import { ISidebarContext } from '../../shared/interfaces/ISidebarContext';

export type PropsType<T extends keyof typeof SidebarMapper> = Omit<
  ComponentProps<(typeof SidebarMapper)[T]>,
  'onHide'
>;

const defaultState: ISidebarContext = {
  activeSidebar: null,
  sidebarProps: {},
  openSidebar: () => {
    throw new Error('openSidebar() must be implemented');
  },
  closeSidebar: () => {
    throw new Error('closeSidebar() must be implemented');
  },
};

const SidebarContext = createContext(defaultState);

export default SidebarContext;
