import React, { Fragment, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import FullScreenLoader from './components/full-screen-loader';
import ComponentDetailsPageInplaceEditProvider from './contexts/component-details-page-inplace-edit/ComponentDetailsPageInplaceEditProvider';
import ProductDetailsPageInplaceEditProvider from './contexts/product-details-page-inplace-edit/ProductDetailsPageInplaceEditProvider';
import GuestGuard from './guard/GuestGuard';
import ProtectedGuard from './guard/ProtectedGuard';
import { useAuth } from './hooks/auth/useAuth';
import AppLayout from './layouts/app/AppLayout';
import AuthLayout from './layouts/auth/AuthLayout';
import ComponentDetailsPage from './pages/component-details/ComponentDetailsPage';
import ComponentsPage from './pages/components/ComponentsPage';
import ProductDetailsPage from './pages/product-details/ProductDetailsPage';
import ProductsPage from './pages/products/ProductsPage';
import RequestsPage from './pages/requests/RequestsPage';
import { AppRoutes } from './shared/enums/app-routes';

type GuardProps = {
  children: React.ReactNode;
};

type LayoutProps = {
  children: React.ReactNode;
  title?: string;
};

type InplaceEditProviderProps = {
  children: React.ReactNode;
};

export type TGeneralRoute = {
  exact?: boolean;
  path: string;
  titleTranslationKey?: string;
  guard?: ({ children }: GuardProps) => JSX.Element;
  layout?: ({ children, title }: LayoutProps) => JSX.Element;
  component: React.LazyExoticComponent<() => JSX.Element> | (() => JSX.Element);
  inplaceEditProvider?: ({ children }: InplaceEditProviderProps) => JSX.Element;
};

export type TRoutes = Array<TGeneralRoute>;

const routes: TGeneralRoute[] = [
  {
    exact: true,
    path: '/',
    guard: GuestGuard,
    component: () => {
      return <Navigate to={AppRoutes.Products} />;
    },
  },
  {
    exact: true,
    path: AppRoutes.Login,
    titleTranslationKey: 'routeTitles.login',
    layout: AuthLayout,
    guard: GuestGuard,
    component: lazy(() => {
      return import('./pages/login/Login');
    }),
  },
  {
    path: AppRoutes.ActivateAccount,
    layout: AuthLayout,
    guard: GuestGuard,
    titleTranslationKey: 'routeTitles.activateAccount',
    component: lazy(() => {
      return import('./pages/activate-account/ActivateAccount');
    }),
  },
  {
    path: AppRoutes.Products,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.products',
    component: ProductsPage,
  },
  {
    path: AppRoutes.ProductDetailsTab,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.productDetails',
    component: ProductDetailsPage,
    inplaceEditProvider: ProductDetailsPageInplaceEditProvider,
  },
  {
    path: AppRoutes.ProductPcfTab,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.productPcf',
    component: ProductDetailsPage,
    inplaceEditProvider: ProductDetailsPageInplaceEditProvider,
  },
  {
    path: AppRoutes.ProductPermissionTab,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.productPermissions',
    component: ProductDetailsPage,
    inplaceEditProvider: ProductDetailsPageInplaceEditProvider,
  },
  {
    path: AppRoutes.ProductFootprintDetails,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.productPcf',
    component: ProductDetailsPage,
    inplaceEditProvider: ProductDetailsPageInplaceEditProvider,
  },
  {
    path: AppRoutes.Components,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.components',
    component: ComponentsPage,
  },
  {
    path: AppRoutes.ComponentDetails,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.componentDetails',
    component: ComponentDetailsPage,
    inplaceEditProvider: ComponentDetailsPageInplaceEditProvider,
  },
  {
    path: AppRoutes.Requests,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.requests',
    component: RequestsPage,
  },
  {
    exact: true,
    layout: AppLayout,
    path: AppRoutes.UserManagement,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.userManagement',
    component: lazy(() => {
      return import('./pages/user-management/UserManagementPage');
    }),
  },
  {
    exact: true,
    path: AppRoutes.NotFound,
    layout: AppLayout,
    guard: ProtectedGuard,
    titleTranslationKey: 'routeTitles.notFound',
    component: lazy(() => {
      return import('./pages/404');
    }),
  },
  {
    exact: true,
    path: AppRoutes.ResetPassword,
    titleTranslationKey: 'routeTitles.resetPassword',
    layout: AuthLayout,
    guard: GuestGuard,
    component: lazy(() => {
      return import('./pages/reset-password/ResetPassword');
    }),
  },
  {
    exact: true,
    path: AppRoutes.ForgotPassword,
    titleTranslationKey: 'routeTitles.forgotPassword',
    layout: AuthLayout,
    guard: GuestGuard,
    component: lazy(() => {
      return import('./pages/forgot-password/ForgotPassword');
    }),
  },
  {
    exact: true,
    path: AppRoutes.PrivacyPolicy,
    titleTranslationKey: 'routeTitles.privacyPolicy',
    guard: GuestGuard,
    component: lazy(() => {
      return import('./pages/privacy-policy/PrivacyPolicy');
    }),
  },
  {
    exact: true,
    path: AppRoutes.TermsOfUse,
    titleTranslationKey: 'routeTitles.termsOfUse',
    guard: GuestGuard,
    component: lazy(() => {
      return import('./pages/terms-of-use/TermsOfUse');
    }),
  },

  {
    exact: true,
    path: '*',
    component: () => {
      return <Navigate to='/404' />;
    },
  },
];

export const AppRouter = (): JSX.Element | null => {
  const { isLoading, isAuthenticated } = useAuth();

  const { t } = useTranslation();

  if (isLoading) {
    return null;
  }

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <Routes>
        {routes.map((route) => {
          const { path, component, inplaceEditProvider, titleTranslationKey, layout, guard } =
            route;
          const Component = component;
          const Layout = layout ?? Fragment;
          const Guard = guard ?? Fragment;
          const InplaceEditProvider = inplaceEditProvider ?? Fragment;

          return (
            <Route
              // eslint-disable-next-line react/no-array-index-key
              key={path}
              path={path}
              element={
                <Guard>
                  <Layout>
                    <Helmet>
                      {titleTranslationKey && (
                        <title>{`PACIFIC - ${t(titleTranslationKey)}`}</title>
                      )}
                    </Helmet>
                    <InplaceEditProvider>
                      <Component />
                    </InplaceEditProvider>
                  </Layout>
                </Guard>
              }
            />
          );
        })}

        <Route
          path='*'
          element={
            isAuthenticated ? (
              <Navigate to={AppRoutes.NotFound} />
            ) : (
              <Navigate to={AppRoutes.Login} />
            )
          }
        />
      </Routes>
    </Suspense>
  );
};
