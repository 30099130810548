import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const PenFilledIcon = (options: IconOptions) => {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        d='M13.0241 0.975613C12.7194 0.670924 12.3061 0.499756 11.8752 0.499756C11.4443 0.499756 11.031 0.670924 10.7262 0.975613L10.01 1.69185L12.3079 3.98976L13.0241 3.27352C13.3288 2.96878 13.5 2.5555 13.5 2.12457C13.5 1.69364 13.3288 1.28035 13.0241 0.975613ZM11.6511 4.64657L9.35318 2.34866L1.83173 9.87011C1.44969 10.252 1.16884 10.723 1.01459 11.2407L0.519348 12.9028C0.495438 12.983 0.493655 13.0682 0.514187 13.1494C0.534719 13.2305 0.576803 13.3046 0.635986 13.3638C0.69517 13.423 0.76925 13.465 0.85039 13.4856C0.93153 13.5061 1.01671 13.5043 1.09692 13.4804L2.75907 12.9852C3.27673 12.8309 3.74779 12.5501 4.12964 12.168L11.6511 4.64657Z'
        fill='#F39500'
      />
    </svg>
  );
};

export default PenFilledIcon;
