/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IRequest } from '../../shared/interfaces/IRequest';

interface IRequestPdfDownloadUrl {
  downloadUrl: string;
}

interface IRequestJsonDownloadUrl {
  data: unknown;
}

type RequestPcfProps = {
  component_id?: number;
  region_type?: string;
  file_type?: string[];
  request_type: string;
  region?: string;
  request_description?: string;
};

export type PcfRequestsParams = {
  request_type?: string;
  search?: string;
};

const amplifyApiService = new AmplifyApiService();

export const useGetPcfRequests = (
  filters: PcfRequestsParams = {},
  options: UseQueryOptions<IRequest[], Error> = {},
): UseQueryResult<IRequest[], Error> => {
  // Clear undefined values from filter

  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    options: {
      queryParams: filters,
    },
  };

  const query = useQuery<IRequest[], Error>({
    queryKey: ['requests', filters],
    queryFn: async (): Promise<IRequest[]> => {
      const response = await amplifyApiService.get<{ requests: IRequest[] }>(amplifyApiConfig);

      return response.requests;
    },
    ...options,
  });

  return query;
};

export const useCreatePcfRequest = (
  options: UseMutationOptions<void, Error, RequestPcfProps> = {},
): UseMutationResult<void, Error, RequestPcfProps> => {
  const mutation = useMutation<void, Error, RequestPcfProps>({
    mutationFn: async (requestPcf: RequestPcfProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: 'request',
        options: {
          body: requestPcf,
        },
      };

      await amplifyApiService.post<RequestPcfProps, { id: number }>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

export const useGetRequestPdfDownloadUrl = (
  id?: number,
): UseQueryResult<IRequestPdfDownloadUrl> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `request/${id}?downloadPdfUrl=1`,
    options: {},
  };

  const query = useQuery<IRequestPdfDownloadUrl, Error>({
    queryKey: ['request-download-pdf-url', id],
    queryFn: async (): Promise<IRequestPdfDownloadUrl> => {
      const response = await amplifyApiService.get<IRequestPdfDownloadUrl>(amplifyApiConfig);

      return response;
    },
    enabled: false,
  });

  return query;
};

export const useGetRequestJsonDownloadUrl = (
  id?: number,
): UseQueryResult<IRequestJsonDownloadUrl> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `request/${id}?downloadJson=1`,
    options: {},
  };

  const query = useQuery<IRequestJsonDownloadUrl, Error>({
    queryKey: ['request-download-json', id],
    queryFn: async (): Promise<IRequestJsonDownloadUrl> => {
      const response = await amplifyApiService.get<IRequestJsonDownloadUrl>(amplifyApiConfig);

      return response;
    },
    enabled: false,
  });

  return query;
};
