import { Button } from 'primereact/button';
import {
  Inplace,
  InplaceContent,
  InplaceDisplay,
  InplaceProps,
  InplaceToggleEvent,
} from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';

import { useInplaceEdit } from '../../../../contexts/product-details-page-inplace-edit/ProductDetailsPageInplaceEditContext';
import styles from './ProductInplaceEdit.module.css';

type InplaceEditProps = InplaceProps & {
  fieldId: string; // The unique identifier of the field, used for single field activation
  undefinedText?: string; // The text to display (in the <InplaceDisplay> part) when the provided value is empty
  value?: string; // The value of the field
  placeholder?: string; // The placeholder of the input field
  disabled?: boolean; // Whether the field is disabled or not
  type?: 'text' | 'other'; // The type of content
  optional?: boolean; // Whether the field is optional or not (allows to save with null value)

  isTextArea?: boolean;

  textClassname?: string; // The classname of the text, both in display and content(input field)
  className?: string; // The classname of the inplace edit container as a whole
  displayClassname?: string; // The classname of the display container (when the input field is not active, i.e. in closed state)
  contentClassname?: string; // The classname of the content container (when the input field is active, i.e. in open state)

  // The custom content to display when the value is not specified
  // Either use this or the undefinedText prop
  customUndefinedDisplay?: JSX.Element;
  customDisplay?: JSX.Element;
  customContent?: JSX.Element;
};

const ProductInplaceEdit = (props: InplaceEditProps) => {
  // Destructure the props
  const {
    fieldId,
    value: initialValue,
    undefinedText,
    placeholder,
    disabled,
    type = 'text',
    optional = false,

    isTextArea,

    textClassname = 'text-md',
    className,
    displayClassname,
    contentClassname,

    customUndefinedDisplay,
    customDisplay,
    customContent,
  } = props;

  // Destructure the inplace edit context
  const { productId, activeFieldId, activate, deactivate, handleSave, isSaving } = useInplaceEdit();

  const { control, setValue } = useForm<{ value?: string }>({
    defaultValues: { value: initialValue },
  });

  const {
    field: { onChange, value: enteredValue },
  } = useController({
    name: 'value',
    control,
  });

  const customContentRef = useRef<HTMLInputElement>(null);

  const [lastSetValue, setLastSetValue] = useState<string | undefined | null>(initialValue);

  const buttonGroupRef = useRef<HTMLDivElement>(null);
  const [buttonGroupWidth, setButtonGroupWidth] = useState(0);

  // Once the buttons are rendered, calculate their width and give that amount of right-padding to the input field
  // So that the buttons don't overlap the text in the input field
  useEffect(() => {
    if (buttonGroupRef.current && activeFieldId === fieldId) {
      setButtonGroupWidth(buttonGroupRef.current.offsetWidth);
    }
  }, [buttonGroupRef, activeFieldId]);

  // Listen for rollback events (only when the field is active)
  useEffect(() => {
    if (activeFieldId === fieldId) {
      const handleRollback = () => {
        setValue('value', initialValue as string);
        deactivate();
      };
      window.addEventListener(`inplace-edit:${fieldId}:rollback`, handleRollback);

      // Cleanup the event listener
      return () => {
        window.removeEventListener(`inplace-edit:${fieldId}:rollback`, handleRollback);
      };
    }
  }, [activeFieldId]);

  // When the active field changes, check if the current field is the active field
  // If active field is not NULL and also not equal to the current field, this means user activated another field without clicking "Cancel" or "Save"
  // In this case, rollback the value to the lastSetValue
  useEffect(() => {
    if (activeFieldId && activeFieldId !== fieldId) {
      setValue('value', lastSetValue as string);
    }
  }, [activeFieldId]);

  // Listen for the value changes if the component is of a non-text type
  // This part is necessary because the value of a non-text type component is not controlled by a controller
  // On contrary, we don't need this for text types since its value is controlled by a a controller (useController)
  useEffect(() => {
    if (type === 'other') {
      setValue('value', initialValue as string);
    }
  }, [initialValue]);

  const onToggle = (e: InplaceToggleEvent) => {
    if (e.value) {
      activate(fieldId);
      if (type === 'other') {
        customContentRef.current?.focus();
      }
    }
  };

  const onCancelClick = () => {
    // Rollback to the initial value and deactivate the field
    if (type === 'text') {
      setValue('value', lastSetValue as string);
    } else {
      setValue('value', initialValue as string); // This is needed because the value of a non-text type component is not controlled by a controller
    }

    deactivate();
  };

  const onSaveClick = () => {
    // Check if the enteredValue is the same as the given value
    if (enteredValue === lastSetValue) {
      setValue('value', lastSetValue as string);
      deactivate();

      return;
    }

    // Check if the enteredValue is empty, then send null to the API (and if the field is optional)
    if ((!(enteredValue ?? '') || (enteredValue ?? '').trim() === '') && optional) {
      setValue('value', '');
      setLastSetValue('');
      handleSave(productId ?? '', fieldId, null);

      return;
    }

    setValue('value', enteredValue);
    setLastSetValue(enteredValue);
    handleSave(productId ?? '', fieldId, enteredValue);
  };

  const getIsSaveButtonDisabled = () => {
    // Check if the value is currently being saved
    if (isSaving) {
      return true;
    }

    if (type === 'text') {
      const current = (enteredValue ?? '')?.trim();
      const last = (lastSetValue ?? '').trim();

      // Check if the entered value is the same as the initial value
      if (current === last) {
        return true;
      }

      // Check if the entered value is empty and the field is optional
      if (current === '' && optional) {
        return false;
      }

      // Check if the entered value is empty
      if (current === '' && !optional) {
        return true;
      }
    } else {
      // Check if the entered value is the same as the initial value
      if (lastSetValue?.trim() === initialValue?.trim()) {
        return true;
      }
    }

    return false;
  };

  const isActive = useMemo(() => {
    return activeFieldId === fieldId;
  }, [activeFieldId]);

  const displayText =
    (lastSetValue ?? '')?.trim() === '' ||
    (lastSetValue ?? '').trim() === undefined ||
    (lastSetValue ?? '').trim() === null
      ? customUndefinedDisplay ?? undefinedText
      : (lastSetValue ?? '').trim();

  return (
    <div className={classNames(styles['inplace-edit'], className)}>
      <Inplace
        active={isActive}
        onToggle={onToggle}
        disabled={disabled}
        onKeyDown={(e) => {
          if (isSaving) return;

          if (e.key === 'Enter' && !isTextArea) {
            if (getIsSaveButtonDisabled()) return;

            onSaveClick();
          } else if (e.key === 'Escape') {
            onCancelClick();
          }
        }}
      >
        {/* The component to show when input field is closed */}
        <InplaceDisplay>
          <div
            className={classNames(
              'flex w-full border-m border-1 border-transparent py-2 border-round-md line-height-2',
              styles['inplace-edit-display-container'],
              displayClassname,
              textClassname,
              {
                'text-gray-500': !lastSetValue,
                'font-italic': !lastSetValue,

                'cursor-auto': isSaving,
                'cursor-pointer': !isSaving,
              },
            )}
          >
            {customDisplay ?? displayText}
          </div>
        </InplaceDisplay>

        {/* The component to show when input field is opened */}
        <InplaceContent>
          <div
            className={classNames(
              'flex w-full align-items-center gap-2 relative',
              contentClassname,
            )}
          >
            {customContent ? (
              <div
                className='w-full'
                style={{ paddingRight: `${buttonGroupWidth}px` }}
                ref={customContentRef}
                tabIndex={0} // Make it focusable
              >
                {customContent}
              </div>
            ) : isTextArea ? (
              // If it is a textarea, then use InputTextarea
              <InputTextarea
                value={enteredValue ?? ''}
                placeholder={placeholder}
                autoFocus
                autoResize={!!isTextArea}
                disabled={isSaving}
                onChange={onChange}
                className={classNames('w-full pl-2 py-2 line-height-2', textClassname)}
                style={{ paddingRight: `${buttonGroupWidth}px` }} // Dynamically set the right padding, based on the buttons' width
              />
            ) : (
              // If it is not a customContent & not a textarea, then use InputText
              <InputText
                value={enteredValue}
                placeholder={placeholder}
                autoFocus
                disabled={isSaving}
                onChange={onChange}
                className={classNames('w-full pl-2 py-2 line-height-2', textClassname)}
                style={{ paddingRight: `${buttonGroupWidth}px` }} // Dynamically set the right padding, based on the buttons' width
              />
            )}

            {/* Button Group */}
            <div
              className={classNames(
                'flex gap-2 absolute right-0 justify-content-center align-items-center px-2 py-2',
                styles['input-field-button-group'],
              )}
              style={{ height: isTextArea ? '100%' : 'auto' }}
              ref={buttonGroupRef}
            >
              <Button
                icon='pi pi-check'
                disabled={getIsSaveButtonDisabled()}
                loading={isSaving}
                onClick={onSaveClick}
              />
              <Button
                icon='pi pi-times'
                className='bg-gray-600'
                disabled={isSaving}
                onClick={onCancelClick}
              />
            </div>
          </div>
        </InplaceContent>
      </Inplace>
    </div>
  );
};

export default ProductInplaceEdit;
