import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetProductById } from '../../../../hooks/api/product';
import { useAuth } from '../../../../hooks/auth/useAuth';
import styles from './ProductTabView.module.css';
import PcfTab from './tabs/pcf-tab/PcfTab';
import PermissionsTab from './tabs/permissions-tab/PermissionsTab';
import ProductDetailsTab from './tabs/product-details-tab/ProductDetailsTab';

const TabIndexMapping = {
  0: 'product-details',
  1: 'pcf',
  2: 'permissions',
};

const ProductTabView = () => {
  // Read params from URL
  const { productId, footprintId } = useParams() as { productId: string; footprintId: string };

  const { user } = useAuth();

  // Fetch product details based on the productId
  const { data: product } = useGetProductById(productId, {
    enabled: !!productId,
    retry: 0,
  });

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Tab management should be done here. i.e. switching tabs should change the URL
  // Or based on the URL parameters, the respective tab should be selected
  // Derive the active tab index based on the URL
  const getActiveTabIndex = (): number => {
    const pathSegments = location.pathname.split('/');
    const activeTab = pathSegments[pathSegments.length - 1];

    if (footprintId) {
      // Prioritize PCF tab if footprintId is present
      return Object.values(TabIndexMapping).indexOf('pcf');
    }

    return Math.max(Object.values(TabIndexMapping).indexOf(activeTab), 0);
  };

  const [activeTabIndex, setActiveTabIndex] = useState<number>(getActiveTabIndex);

  // Sync tab state with URL changes
  useEffect(() => {
    const activeTabIndex = getActiveTabIndex();
    setActiveTabIndex(activeTabIndex);
  }, [location]);

  const handleTabChange = (event: TabViewTabChangeEvent) => {
    const href = `/products/${product?.id}/${
      TabIndexMapping[event.index as keyof typeof TabIndexMapping]
    }`;
    navigate(href);
    setActiveTabIndex(event.index);
  };

  return (
    <TabView
      className={styles['product-tab-view']}
      pt={{
        nav: { className: 'flex flex-row gap-6' },
      }}
      activeIndex={activeTabIndex}
      onTabChange={handleTabChange}
    >
      {/* Product Details Tab */}
      <TabPanel header={t('productDetailsPage.tabs.productDetailsTab.tabHeader')}>
        <ProductDetailsTab />
      </TabPanel>

      {/* PCF Tab */}
      <TabPanel header={t('productDetailsPage.tabs.pcfTab.tabHeader')}>
        <PcfTab />
      </TabPanel>

      {/* Permisions */}
      <TabPanel
        header={t('productDetailsPage.tabs.permissionsTab.tabHeader')}
        visible={user?.company?.license === 'automotive'}
      >
        <PermissionsTab />
      </TabPanel>
    </TabView>
  );
};

export default ProductTabView;
