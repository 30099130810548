import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import useDialogContext from '../../../../../../../../hooks/dialog/useDialogContext';

const AddCompanyAccessButton = (): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  const onClick = () => {
    openDialog('add-company-access-dialog');
  };

  return (
    <Button
      onClick={onClick}
      label={t('productDetailsPage.tabs.permissionsTab.buttons.addCompanyAccess')}
      icon='pi pi-plus-circle'
      className='max-w-fit flex'
    />
  );
};

export default AddCompanyAccessButton;
