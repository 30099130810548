import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '../../../../../i18n';
import { parseDateWithoutTime } from '../../../../../shared/helpers/parse-date';
import { packagingEmissionValueToLabelKey } from '../../../../../shared/interfaces/IFootprint';
import { IComponentDetails } from '../../../../../shared/interfaces/IProductDetails';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';

interface CarbonFootprintDetailsSectionProps extends ComponentProps<'div'> {
  componentDetais?: IComponentDetails;
  isLoading?: boolean;
}

const CarbonFootprintDetailsSection = (props: CarbonFootprintDetailsSectionProps): JSX.Element => {
  const { componentDetais, isLoading, ...rest } = props;
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>
        {t('componentDetailsPage.sections.carbonFootprintDetailsSection.carbonFootprintDetails')}
      </h5>

      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.carbonFootprintDetailsSection.crossSectoralStandard',
        )}
      >
        {componentDetais?.pcf.crossSectoralStandardsUsed[0].crossSectoralStandard}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.carbonFootprintDetailsSection.productOrSectorSpecificRules',
        )}
      >
        {componentDetais?.pcf.productOrSectorSpecificRules[0].productOrSectorSpecificRules
          .map((rule) => {
            return rule.ruleName;
          })
          .join(' | ')}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.carbonFootprintDetailsSection.secondaryEmissionFactorSources',
        )}
      >
        {componentDetais?.pcf.secondaryEmissionFactorSources
          .map((item) => {
            return item.secondaryEmissionFactorSource;
          })
          .join(' | ')}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'componentDetailsPage.sections.carbonFootprintDetailsSection.characterizationFactors',
        )}
      >
        {componentDetais?.pcf.extWBCSD_characterizationFactors}
      </InfoCardRow>

      <InfoCardRow
        label={t('componentDetailsPage.sections.carbonFootprintDetailsSection.packagingEmissions')}
      >
        <div className='flex flex-row gap-2'>
          <span>
            {t(
              packagingEmissionValueToLabelKey(
                String(componentDetais?.pcf?.extWBCSD_packagingEmissionsIncluded),
              ),
            )}
          </span>
        </div>
      </InfoCardRow>

      <InfoCardRow
        label={t('componentDetailsPage.sections.carbonFootprintDetailsSection.partialOrFullPcf')}
      >
        {componentDetais?.partialFullPcf}
      </InfoCardRow>

      <InfoCardRow
        label={t('componentDetailsPage.sections.carbonFootprintDetailsSection.referencePeriod')}
      >
        {parseDateWithoutTime(componentDetais?.pcf.referencePeriodStart, language as Locale)} -{' '}
        {parseDateWithoutTime(componentDetais?.pcf.referencePeriodEnd, language as Locale)}
      </InfoCardRow>

      <InfoCardRow
        label={t('componentDetailsPage.sections.carbonFootprintDetailsSection.dateCreated')}
      >
        {parseDateWithoutTime(componentDetais?.created, language as Locale)}
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(CarbonFootprintDetailsSection);
