import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ScrollPanel } from 'primereact/scrollpanel';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  NotificationsParams,
  useListNotifications,
  useUpdateNotification,
} from '../../../../../hooks/api/notifications';
import useNotificationContext from '../../../../../hooks/notification/useNotificationContext';
import { NotificationMessage } from '../../../../../shared/enums/notification-message-map';
import { timeAgo } from '../../../../../shared/helpers/time-ago';
import { INotification } from '../../../../../shared/interfaces/INotification';
import styles from './NotificationList.module.css';

export const TOTAL_NOTIFICATIONS_LIST_SIZE = 10;

const NotificationList = () => {
  const { setIsNewNotification } = useNotificationContext();

  const [notificationsParams, setNotificationsParams] = useState<NotificationsParams>({
    size: TOTAL_NOTIFICATIONS_LIST_SIZE,
    skip: 0,
  });
  const [loadedNotifications, setLoadedNotifications] = useState<INotification[]>([]);
  const [newNotificationCount, setNewNotificationCount] = useState(0);

  const {
    isLoading: notificationLoading,
    isError,
    data,
  } = useListNotifications(notificationsParams);

  const { mutate: handleNotificationRead } = useUpdateNotification();

  const onNotificationClick = (notification: INotification) => {
    if (!notification.read) {
      handleNotificationRead(notification.id, {
        onSuccess: async (data, variables) => {
          // If updating notification status in the database is successful, then:
          const id = variables as number;

          // Mark this notification as read
          const notifIndex = loadedNotifications.findIndex((notification) => {
            return notification.id === id;
          });

          // Update notifications in the state
          const updatedNotifications = [...loadedNotifications];
          updatedNotifications[notifIndex].read = true;
          setLoadedNotifications(updatedNotifications);

          // Check if there's any unread notification left
          const numberOfUnreadNotifications = countUnreadNotifications(updatedNotifications);

          setNewNotificationCount(numberOfUnreadNotifications); // For the display "You have XX new notifications"
          setIsNewNotification(numberOfUnreadNotifications > 0); // For the green dot
        },
      });
    }
  };

  /**
   * This useEffect handles when there's a new response from notifications API;
   * * Combines them into a single array
   * * Sets the unread notification count
   */
  useEffect(() => {
    if (!data?.notifications || data?.notifications.length === 0) {
      return;
    }

    setLoadedNotifications((prevNotifications) => {
      const prevNotificationsCp = [...prevNotifications]; // Copy the existing notifications

      // Add newly fetched notifications to the existing notifications list
      data.notifications.forEach((notification) => {
        prevNotificationsCp.push(notification);
      });

      return prevNotificationsCp;
    });
  }, [data?.notifications]);

  /**
   * This useEffect updates the new notification count and the isNewNotification state
   * whenever loadedNotifications changes.
   */
  useEffect(() => {
    const numberOfUnreadNotifications = countUnreadNotifications(loadedNotifications);

    setNewNotificationCount(numberOfUnreadNotifications); // For the display "You have XX new notifications"
    setIsNewNotification(numberOfUnreadNotifications > 0); // For the green dot
  }, [loadedNotifications, setIsNewNotification]);

  const countUnreadNotifications = (notifications: INotification[]): number => {
    return notifications.filter((val) => {
      return !val.read;
    }).length;
  };

  const loadMoreNotifications = () => {
    setNotificationsParams((params) => {
      return {
        ...params,
        skip: (params.skip ?? 0) + TOTAL_NOTIFICATIONS_LIST_SIZE,
      };
    });
  };

  const { t } = useTranslation();

  const formDescriptionText = (notification: INotification) => {
    let text;

    if (
      notification.message === NotificationMessage.CreateRequestSustainableAlternative ||
      notification.message === NotificationMessage.CreateRequestUpdatePcfValues
    ) {
      // ******** ONLY FOR SUSTAINABLE ALTERNATIVE AND UPDATE PCF VALUES ********
      // Check if metadata exists in the details column:
      // If yes, then use it and translate the rest of the text
      // If not, that means the database entry is created with English text, i.e. don't use metadata, don't translate anything, just show what's written in the details.description
      if (!notification.details.description) {
        if (notification.details.component_name !== undefined) {
          text = t('notifications.requestSustainableAlternative.details', {
            componentName: notification.details.component_name,
          });
        } else if (notification.details.total_requests_updated !== undefined) {
          text = t('notifications.updatePcfValues.details', {
            totalRequestsUpdated: notification.details.total_requests_updated,
          });
        }
      } else {
        text = notification.details.description;
      }
    } else if (notification.message !== NotificationMessage.CreateRequestSustainableAlternative) {
      text = t(`notificationsDialog.${notification.message}`);

      if (
        notification.message === NotificationMessage.CreateRequestAdditionalProduct ||
        notification.message === NotificationMessage.UpdateRequestAdditionalProduct
      ) {
        text += ' ' + notification.details.component_name;
      }
    }

    return (
      <span
        className={classNames('fs-small', {
          'font-semibold': !notification.read,
        })}
      >
        {text}
      </span>
    );
  };

  const formTimeAgoText = (notification: INotification) => {
    if (
      notification.message !== NotificationMessage.CreateRequestSustainableAlternative &&
      notification.message !== NotificationMessage.CreateRequestUpdatePcfValues
    ) {
      return (
        <div className='flex justify-content-between align-items-end'>
          <span className='fs-small'>{t(`pcfRequest.${notification.details.status}`)}</span>
          <small className='text-grey-100'>{timeAgo(notification.date_created)}</small>
        </div>
      );
    }

    return null;
  };

  const renderAllNotitications = () => {
    return (
      <>
        <li className='mb-3 px-4'>
          <span className='fs-small font-semibold'>
            {newNotificationCount > 0 ? (
              <>
                {t('notificationsDialog.youHaveText')}{' '}
                <span className='text-primary'>{newNotificationCount}</span>{' '}
                {t('notificationsDialog.newNotifications')}
              </>
            ) : (
              // eslint-disable-next-line quotes
              <> {t('notificationsDialog.noNotificationText')}</>
            )}
          </span>
        </li>

        {loadedNotifications.map((notification: INotification) => {
          return (
            <li
              className={classNames(
                'mb-3 p-2 pl-4 border-bottom-1 border-gray-100',
                styles['notification-list-item'],
                { 'cursor-pointer': !notification.read },
              )}
              key={notification.id}
              onClick={() => {
                onNotificationClick(notification);
              }}
            >
              <div className='grid grid-cols-12 gap-2'>
                <div className='col-11'>
                  <div className='flex flex-column gap-2'>
                    {formDescriptionText(notification)}
                    {formTimeAgoText(notification)}
                  </div>
                </div>
                <div
                  className={classNames(
                    'col-1 border-circle my-auto p-0',
                    styles['new-notification-dot'],
                    {
                      'bg-primary': !notification.read,
                    },
                  )}
                />
              </div>
            </li>
          );
        })}

        {data?.pagination && loadedNotifications.length < data.pagination.total && (
          <li className='text-center'>
            <Button
              label={t('notificationsDialog.showMore')}
              text
              onClick={loadMoreNotifications}
            />
          </li>
        )}
      </>
    );
  };

  return (
    <>
      <ScrollPanel style={{ width: '100%', height: '250px' }} className='p-0'>
        <ul className='list-none p-0 mx-0 my-3'>
          {notificationLoading && (
            <li>
              <ProgressSpinner className='w-full' />
            </li>
          )}

          {!notificationLoading && !isError && renderAllNotitications()}
        </ul>
      </ScrollPanel>
    </>
  );
};

export default NotificationList;
