import { useState } from 'react';

type CustomIconProps = {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  hoverIcon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconOptions?: React.SVGProps<SVGSVGElement>;
  className?: string;
  onClick?: () => void;
  showTextOnHover?: boolean;
  hoverText?: string;
  hoverTextColor?: string;
};

const CustomIcon = ({
  icon,
  hoverIcon,
  iconOptions,
  className,
  onClick,
  showTextOnHover = false,
  hoverText,
  hoverTextColor,
}: CustomIconProps): JSX.Element => {
  const Icon = icon;
  const HoverIcon = hoverIcon;
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onMouseEnter = (): void => {
    setIsHovered(true);
  };

  const onMouseLeave = (): void => {
    setIsHovered(false);
  };

  return (
    <div className='flex flex-row gap-1 align-items-center'>
      <i
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role={onClick ? 'button' : undefined}
        onClick={onClick}
      >
        {isHovered && HoverIcon ? <HoverIcon {...iconOptions} /> : <Icon {...iconOptions} />}
      </i>
      {isHovered && showTextOnHover && (
        <span className={`text-${hoverTextColor}`}>{hoverText}</span>
      )}
    </div>
  );
};

export default CustomIcon;
