import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

const ComponentsOutlineIcon = (options: IconOptions) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <g opacity='0.8' clipPath='url(#clip0_8713_3077)'>
        <path
          d='M1 4.81731C1 4.22815 1.47815 3.75 2.06731 3.75H7.75962C8.34877 3.75 8.82692 4.22815 8.82692 4.81731V8.375C8.82692 8.96415 8.34877 9.44231 7.75962 9.44231H2.06731C1.78424 9.44231 1.51277 9.32986 1.31261 9.1297C1.11245 8.92954 1 8.65807 1 8.375V4.81731ZM12.3846 6.24038C12.3846 5.65123 12.8628 5.17308 13.4519 5.17308H18.4327C19.0218 5.17308 19.5 5.65123 19.5 6.24038V14.0673C19.5 14.6565 19.0218 15.1346 18.4327 15.1346H13.4519C13.1689 15.1346 12.8974 15.0222 12.6972 14.822C12.4971 14.6218 12.3846 14.3504 12.3846 14.0673V6.24038ZM2.42308 13.3558C2.42308 12.7666 2.90123 12.2885 3.49038 12.2885H8.47115C9.06031 12.2885 9.53846 12.7666 9.53846 13.3558V15.4904C9.53846 16.0795 9.06031 16.5577 8.47115 16.5577H3.49038C3.20732 16.5577 2.93584 16.4452 2.73568 16.2451C2.53553 16.0449 2.42308 15.7735 2.42308 15.4904V13.3558Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_8713_3077'>
          <rect width='20' height='20' fill='white' transform='translate(0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComponentsOutlineIcon;
