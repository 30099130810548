import { useTranslation } from 'react-i18next';

import {
  ALLOWED_INPUT_PRODUCT_MASS_UNITS,
  getProductMassPerUnit,
} from '../../../../../../shared/helpers/product-mass-helpers';
import { textifyDeclaredUnit } from '../../../../../../shared/helpers/textify-numerical-footprint-values-with-their-units';
import { DeclaredUnit } from '../../../../../../shared/interfaces/IProductDetails';

type ProductMassProps = {
  declaredUnit?: DeclaredUnit;
  productMass?: number;
};

const ProductMass = (props: ProductMassProps): JSX.Element => {
  const { productMass, declaredUnit } = props;

  const { t } = useTranslation();

  if (declaredUnit == null || productMass == null) {
    return <span className='font-light text-black-alpha-60'>{t('global.notSpecified')}</span>;
  }

  const productMassDisplayValue = getProductMassPerUnit(declaredUnit, productMass);

  const showPerUnitText = ALLOWED_INPUT_PRODUCT_MASS_UNITS.includes(declaredUnit);

  return (
    <span className='font-light text-black-alpha-60'>
      {productMassDisplayValue}{' '}
      {showPerUnitText ? `kg ${t('global.per')} ${textifyDeclaredUnit(declaredUnit)}` : ''}
    </span>
  );
};

export default ProductMass;
