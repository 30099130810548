import { Locale } from '../../../../../../i18n';
import { User } from '../../../../../../shared/types/User';

export default function (user: User | null, language: 'en' | 'de' = 'en'): string {
  return language === Locale.en
    ? `Dear PACIFIC Team,

  I am reaching out for assistance regarding PACIFIC Web Application. Here are the details of my request:

  - Full Name: ${user?.givenName ?? ''} ${user?.familyName ?? ''}
  - Account Email: ${user?.email}
  - My Question:

  Thank you for your attention and feedback to this matter.

  Best regards,

  ${user?.givenName ?? ''} ${user?.familyName ?? ''}`
    : `Sehr geehrtes PACIFIC-Team,

    ich wende mich an Sie, um Unterstützung bezüglich der PACIFIC-Webanwendung zu erhalten. Hier sind die Details meines Berichts:

    - Vollständiger Name: ${user?.givenName} ${user?.familyName}
    - Konto-E-Mail: ${user?.email}
    - Problem:

    - Finden Sie angehängte Screenshots, die helfen könnten, das Problem besser zu verstehen:

    Vielen Dank für Ihre Aufmerksamkeit und Ihr Feedback zu diesem Anliegen.

    Mit freundlichen Grüßen,

    ${user?.givenName ?? ''} ${user?.familyName ?? ''}`;
}
