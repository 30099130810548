import { TreeNode } from 'primereact/treenode';
import { TreeSelect, TreeSelectEventNodeEvent, TreeSelectProps } from 'primereact/treeselect';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import countries from '../../../../../../assets/static/countries.json';
import { CountryCodeToRegionCodeMap } from '../../../../../../shared/helpers/country-region-helpers';
import { prepareFlagIconsRecursively } from '../../../../../../shared/helpers/prepare-flag-icons-recursively';
import styles from './LocationTreeSelect.module.css';

interface LocationTreeSelectProps extends TreeSelectProps {
  onValueSelect: (regionCode: string, countryCode?: string) => void;
  placeholder?: string;
  error?: string;
  wrapperClassname?: string;
}

const LocationTreeSelect = (props: LocationTreeSelectProps): JSX.Element => {
  const { onValueSelect, placeholder, error, wrapperClassname, value, ...restProps } = props;
  const { t } = useTranslation();

  const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);

  useEffect(() => {
    prepareFlagIconsRecursively(countries);
  }, [countries]);

  const onNodeSelect = (e: TreeSelectEventNodeEvent) => {
    setSelectedNode(e.node);

    let countryCode = undefined;
    let regionCode = '';

    if (!e.node.children) {
      // If the selected node has no children, then it is a country node
      countryCode = e.node.key as string;

      // Find the closest parent node of the selected country
      regionCode = CountryCodeToRegionCodeMap[countryCode];
    } else {
      // If the selected node has children, then it is a region node
      regionCode = e.node.key as string;
    }

    onValueSelect(regionCode, countryCode);
  };

  const valueTemplate = (node: TreeNode[] | TreeNode) => {
    const selectedNode = (node as TreeNode[])[0];
    const defaultPlaceholder = t('addProductDialog.locationTreeSelect.placeholder');

    if (!selectedNode) {
      return <span>{placeholder || defaultPlaceholder}</span>;
    }

    return (
      <div className='flex flex-row align-items-center'>
        {selectedNode.icon && (selectedNode.icon as JSX.Element)}
        <span>{selectedNode.label}</span>
      </div>
    );
  };

  return (
    <div className={classNames('flex flex-column gap-1', wrapperClassname)}>
      <TreeSelect
        value={(selectedNode?.key as string) ?? value}
        options={countries}
        selectionMode='single'
        onNodeSelect={onNodeSelect}
        valueTemplate={valueTemplate}
        filter
        filterInputAutoFocus
        pt={{
          wrapper: {
            className: styles['wrapper'],
          },
          closeButton: {
            className: 'hidden',
          },
        }}
        className='w-full'
        {...restProps}
      />
      {error && <small className='text-red-500 text-sm'>{error}</small>}
    </div>
  );
};

export default LocationTreeSelect;
