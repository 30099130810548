import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import {
  FootPrintStatus,
  IFootPrint,
  IFootPrintDetails,
  PartialFullPcf,
  PCFEntity,
} from '../../shared/interfaces/IFootprint';

const amplifyApiService = new AmplifyApiService();

export type CreatePcfProps = {
  product_id: number;
  date_created: string;
  product_code_cpc: string;
  declared_unit: string;
  unitary_product_amount: number;
  product_mass_per_declared_unit: number;
};

export type CratePcfResponse = {
  id: string;
};

export type UpdateFootprintDetailsProps = {
  pcfId?: string;
  status?: FootPrintStatus;
  date_created?: string;
  partial_full_pcf?: PartialFullPcf;
  product_code_cpc?: string;
  pcf?: Partial<PCFEntity>;
};

/**
 * This function retrieves footprints by product ID using a specified API configuration and query
 * options.
 * @param {string} productId - The `productId` parameter is a string that represents the unique
 * identifier of a product for which you want to retrieve footprints.
 * @param options - The `options` parameter in the `useGetFootprintsByProductId` function is used to
 * provide additional configuration options for the query. These options can include settings such as
 * caching, polling intervals, error handling, and more. By default, an empty object is provided as the
 * default value for the `
 * @returns The `useGetFootprintsByProductId` custom hook is being returned. This hook is used to fetch
 * footprints data by product ID using a specified API configuration and query function.
 */
export const useGetFootprintsByProductId = (
  productId: string,
  options: UseQueryOptions<IFootPrint[], Error> = {},
): UseQueryResult<IFootPrint[], Error> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'footprints',
    options: {
      queryParams: {
        product_id: productId, // eslint-disable-line camelcase
      },
    },
  };

  const query = useQuery<IFootPrint[], Error>({
    queryKey: ['list-footprint', productId],
    queryFn: async (): Promise<IFootPrint[]> => {
      const response = await amplifyApiService.get<IFootPrint[]>(amplifyApiConfig);

      return response || [];
    },
    ...options,
  });

  return query;
};

/**
 * The function `useGetFootprintById` retrieves a product footprint by its ID using an API call and
 * returns the query result.
 * @param {string} footprintId - The `footprintId` parameter is a string that represents the unique
 * identifier of a footprint.
 * @param options - The `options` parameter in the `useGetFootprintById` function is used to provide
 * additional configuration options for the query. These options can include settings such as caching
 * behavior, polling intervals, error handling, and more. By default, the `options` parameter is an
 * empty object `{}` but
 * @returns The `useGetFootprintById` custom hook is being returned, which takes a `footprintId` string
 * and optional `options` object as parameters and returns a `UseQueryResult<IFootPrintDetails[]>` object. This
 * hook fetches data for a specific footprint by its ID using the `amplifyApiService` and returns the
 * response as an array of `IFootPrintDetails` objects.
 */
export const useGetFootprintById = (
  footprintId: string,
  options: UseQueryOptions<IFootPrintDetails, Error> = {},
): UseQueryResult<IFootPrintDetails> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `footprints/${footprintId}`,
    options: {},
  };

  const query = useQuery<IFootPrintDetails, Error>({
    queryKey: ['get-footprint', footprintId],
    queryFn: async (): Promise<IFootPrintDetails> => {
      const response = await amplifyApiService.get<IFootPrintDetails>(amplifyApiConfig);

      return response;
    },
    ...options,
  });

  return query;
};

/**
 * Custom hook to create a Product Carbon Footprint (PCF) using a mutation.
 *
 * @param options - Optional configuration for the mutation, extending the default mutation options.
 * @returns A mutation result object that includes methods and state for the mutation.
 *
 * This hook utilizes the AmplifyApiService to send a POST request to the 'footprints' endpoint
 * of the 'PacificApi'. The request body is constructed from the provided CreatePcfProps.
 */
export const useCreatePcf = (
  options: UseMutationOptions<CratePcfResponse, Error, CreatePcfProps> = {},
): UseMutationResult<CratePcfResponse, Error, CreatePcfProps> => {
  const mutation = useMutation<CratePcfResponse, Error, CreatePcfProps>({
    mutationFn: async (body: CreatePcfProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: 'footprints',
        options: { body },
      };

      return await amplifyApiService.post<CreatePcfProps, CratePcfResponse>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};

/**
 * Custom hook to update footprint details using a mutation.
 *
 * This hook utilizes the `useMutation` hook from `@tanstack/react-query` to perform
 * an asynchronous update operation on footprint details. It sends a PUT request
 * to the 'PacificApi' endpoint with the specified `pcfId` and request body.
 *
 * @param options - Optional mutation options to configure the mutation behavior.
 * @returns A mutation result object containing the mutation state and functions.
 */
export const useUpdateFootprintDetails = (
  options: UseMutationOptions<boolean, Error, UpdateFootprintDetailsProps> = {},
): UseMutationResult<boolean, Error, UpdateFootprintDetailsProps> => {
  const mutation = useMutation<boolean, Error, UpdateFootprintDetailsProps>({
    mutationFn: async ({ pcfId, ...body }: UpdateFootprintDetailsProps) => {
      const amplifyApiConfig: IAmplifyApiConfig = {
        apiName: 'PacificApi',
        path: `footprints/${pcfId}`,
        options: { body },
      };

      return amplifyApiService.put<Partial<UpdateFootprintDetailsProps>, boolean>(amplifyApiConfig);
    },
    ...options,
  });

  return mutation;
};
