import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import FileJSONIcon from '../../assets/icons/FileJSON';
import FileJSONGrayIcon from '../../assets/icons/FileJSONGray';
import { useGetRequestJsonDownloadUrl } from '../../hooks/api/request-pcf';
import { useAuth } from '../../hooks/auth/useAuth';
import { Locale } from '../../i18n';
import { SUPPORT_EMAIL } from '../../shared/constants/emails.constant';
import generateEmailBody from './generateEmailBody';

export type DownloadJsonFileButtonProps = {
  className?: string;
  componentName: string;
  requestId: number;
  showDownloadText?: boolean;
};

const DownloadJsonFileButton = ({
  className,
  componentName,
  requestId,
  showDownloadText = false,
}: DownloadJsonFileButtonProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const isDisabled = user?.company?.license === 'basic';

  const { refetch: fetchJsonDownload } = useGetRequestJsonDownloadUrl(requestId);

  const handleClick = async () => {
    const { data, isSuccess } = await fetchJsonDownload();

    if (isSuccess && data) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = `${componentName.toLowerCase().replace(' ', '_')}_pcf.json`;

      link.click();

      queryClient.invalidateQueries({ queryKey: ['listComponents'] });
    }
  };

  const handleEmailClick = () => {
    const emailSubject = t('emails.upgradeInquiry.subject');
    let emailBody = generateEmailBody(user, language as Locale);

    // Encode the email body for the mailto link
    emailBody = encodeURIComponent(emailBody);

    // Create the mailto link with the recipient, subject, and body
    const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${emailSubject}&body=${emailBody}`;

    // Open the user's default email client with the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <div>
      {isDisabled && (
        <Tooltip
          target={`.json-download-btn-${requestId}`}
          position='top'
          autoHide={false}
          baseZIndex={1}
          pt={{ root: { className: 'pointer-events-auto' } }}
        >
          <div>
            <span>{t('componentsPage.jsonDownloadDisabledTooltip.part1')}</span>{' '}
            <Button
              link
              label={t('componentsPage.jsonDownloadDisabledTooltip.clickHere')}
              className='p-0'
              onClick={handleEmailClick}
            />{' '}
            <span>{t('componentsPage.jsonDownloadDisabledTooltip.part2')}</span>
          </div>
        </Tooltip>
      )}
      <Button
        className={classNames(
          `p-0 json-download-btn-${requestId}`,
          {
            'cursor-pointer': !isDisabled,
            'cursor-auto': isDisabled,
          },
          className,
        )}
        icon={isDisabled ? FileJSONGrayIcon : FileJSONIcon}
        link
        onClick={handleClick}
      />
      {showDownloadText ? (
        <Button className='text-primary underline' link onClick={handleClick}>
          {t('downloadPcfDialog.downloadJSONFile')}
        </Button>
      ) : null}
    </div>
  );
};

export default DownloadJsonFileButton;
