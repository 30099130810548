import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';

import EmptyScreen from '../../../../../../../../components/empty-screen/EmptyScreen';
import useDialogContext from '../../../../../../../../hooks/dialog/useDialogContext';
import { Locale } from '../../../../../../../../i18n';
import { parseDateWithoutTime } from '../../../../../../../../shared/helpers/parse-date';
import { dummyDataType } from '../../PermissionsTab';
import AddCompanyAccessButton from '../add-company-access-button/AddCompanyAccessButton';
import styles from './PermissionsTable.module.css';

const PermissionsTable = ({ dummyData }: { dummyData: dummyDataType[] }): JSX.Element => {
  const { openDialog } = useDialogContext();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const withdrawTemplate = (rowData: dummyDataType): JSX.Element => {
    return (
      <Button
        outlined
        label={t('productDetailsPage.tabs.permissionsTab.buttons.withdraw')}
        onClick={() => {
          return openDialog('withdraw-permission-dialog', {
            dummyData: rowData,
          });
        }}
      />
    );
  };

  const dateApprovedTemplate = (rowData: dummyDataType): JSX.Element => {
    return <span>{parseDateWithoutTime(rowData.dateApproved, language as Locale)}</span>;
  };

  if (!dummyData.length) {
    return (
      <EmptyScreen>
        <p className='flex text-center font-light'>
          {t('productDetailsPage.tabs.permissionsTab.emptyScreenText')}
        </p>
        <AddCompanyAccessButton />
      </EmptyScreen>
    );
  }

  return (
    <div className={styles['permissions-table']}>
      <DataTable
        value={dummyData}
        pt={{
          wrapper: {
            className: styles['table-wrapper'],
          },
        }}
      >
        <Column
          field='companyName'
          sortable
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.companyName')}
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column
          field='bpn'
          sortable
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.bpn')}
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column
          field='dateApproved'
          sortable
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.dateApproved')}
          body={dateApprovedTemplate}
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column
          field='approvedBy'
          sortable
          header={t('productDetailsPage.tabs.permissionsTab.permissionsTableColumns.approvedBy')}
          style={{ minWidth: '12rem', maxWidth: '16rem' }}
        />
        <Column body={withdrawTemplate} style={{ minWidth: '12rem', maxWidth: '16rem' }} />
      </DataTable>
    </div>
  );
};

export default PermissionsTable;
