import { Locale } from '../../i18n';
import { User } from '../../shared/types/User';

export default function (user: User | null, language: Locale): string {
  return language === Locale.en
    ? `Dear PACIFIC Team,

    I am reaching out to request information about paid upgrades for PACIFIC. Please share more detailed information and get in touch with me.

    Thank you.

    Best regards,
    ${user?.givenName} ${user?.familyName}
    ${user?.email}`
    : `Liebes PACIFIC-Team,

    ich möchte Informationen über kostenpflichtige Upgrades für PACIFIC anfordern. Bitte teilen Sie mir detailliertere Informationen mit und setzen Sie sich mit mir in Verbindung.

    Vielen Dank.

    Mit freundlichen Grüßen,
    ${user?.givenName} ${user?.familyName}
    ${user?.email}`;
}
