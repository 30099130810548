import { useTranslation } from 'react-i18next';

import { EmissionFactorDSSet } from '../../interfaces/IFootprint';
// TODO: Move this to a shared utility file
const secondaryDataSourceMapper = (source: EmissionFactorDSSet) => {
  const secondaryEmissionSource = source.secondary_emission_factor_source.split(' ');
  const version = secondaryEmissionSource.pop();
  const name = secondaryEmissionSource.join(' ');

  return {
    name,
    version,
  };
};

type SecondaryEmissionsFactorSourcesProps = {
  sources: EmissionFactorDSSet[];
};

// Component to display the secondary emissions factor sources, each source is concatenated into one single string, separated by '|'
const SecondaryEmissionsFactorSources = (
  props: SecondaryEmissionsFactorSourcesProps,
): JSX.Element => {
  const { sources } = props;

  const { t } = useTranslation();

  if (!sources.length) {
    return <span className='text-black-alpha-60'>{t('global.notSpecified')}</span>;
  }

  const mappedSources = sources.map(secondaryDataSourceMapper);

  return (
    <span className='flex w-full text-black-alpha-60'>
      {mappedSources
        .map((source) => {
          return `${source.name} v${source.version}`;
        })
        .join(' | ')}
    </span>
  );
};

export default SecondaryEmissionsFactorSources;
