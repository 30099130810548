import { createContext, useContext } from 'react';

export type IProductDetailsInplaceEditContext = {
  productId: string | null;
  setProductId: (productId: string) => void;

  activeFieldId: string | null;

  activate: (fieldId: string) => void;
  deactivate: () => void;

  handleSave: <T>(productId: string, fieldId: string, value: T) => void;

  isSaving: boolean;
};

// Create a context
export const InplaceEditContext = createContext({} as IProductDetailsInplaceEditContext);

export const useInplaceEdit = () => {
  return useContext(InplaceEditContext);
};
