import { Tag } from 'primereact/tag';
import { useTranslation } from 'react-i18next';

import { CrossSectoralStandardV2 } from '../../interfaces/IFootprint';
import styles from './CrossSectoralStandards.module.css';

type CrossSectionalStandardsProps = {
  standards: CrossSectoralStandardV2[];
};

const CrossSectionalStandards = (props: CrossSectionalStandardsProps): JSX.Element => {
  const { standards } = props;

  const { t } = useTranslation();

  if (!standards.length) {
    return <span className='text-black-alpha-60'>{t('global.notSpecified')}</span>;
  }

  return (
    <div className='flex flex-wrap gap-1 w-full'>
      {standards.map((std) => {
        return (
          <Tag
            key={std.cross_sectoral_standard}
            value={std.cross_sectoral_standard}
            className={styles['cross-sectoral-standard-tag']}
            pt={{
              value: {
                className: 'line-height-1 py-1 px-2 font-light text-black-alpha-90 text-sm',
              },
            }}
          />
        );
      })}
    </div>
  );
};

export default CrossSectionalStandards;
