import React, { useCallback, useMemo, useState } from 'react';

import { SidebarMapper, SidebarType } from '../../components/sidebar-manager/SidebarMapper';
import { ISidebarContext } from '../../shared/interfaces/ISidebarContext';
import SidebarContext, { PropsType } from './SidebarContext';

interface SidebarProviderProps {
  children: React.ReactNode;
}

export const SidebarProvider = ({ children }: SidebarProviderProps): JSX.Element => {
  const [activeSidebar, setActiveSidebar] = useState<SidebarType | null>(null);
  const [sidebarProps, setSidebarProps] = useState<PropsType<SidebarType>>({});

  const closeSidebar = useCallback((): void => {
    if (activeSidebar !== null && sidebarProps?.visible) {
      // When we want to close the sidebar, we set props.visible to false
      // So that the side-effects of the sidebar will be cleaned up by PrimeReact itself
      setSidebarProps({ ...sidebarProps, visible: false });
    }
  }, [activeSidebar, sidebarProps]);

  const openSidebar = useCallback(
    <T extends SidebarType>(sidebar: T, props?: PropsType<T>): void => {
      if (sidebar in SidebarMapper) {
        // If such sidebar is defined in SidebarMapper:
        // 1. Set props.visible to true so that the sidebar will be shown by PrimeReact
        // 2. Set activeSidebar to the sidebar and props
        setActiveSidebar(sidebar);
        setSidebarProps({ ...props, visible: true });
      }
    },
    [activeSidebar, sidebarProps, closeSidebar],
  );

  const state = useMemo<ISidebarContext>(() => {
    return {
      activeSidebar,
      sidebarProps,
      openSidebar,
      closeSidebar,
    };
  }, [activeSidebar, openSidebar, closeSidebar]);

  return <SidebarContext.Provider value={state}>{children}</SidebarContext.Provider>;
};
