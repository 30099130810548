import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import { FootPrintStatus as FootprintStatusEnum } from '../../../../../../../../../shared/interfaces/IFootprint';
import styles from './FootprintStatus.module.css';

type FootprintStatusProps = {
  status: FootprintStatusEnum;
};

export default function FootprintStatus({ status }: FootprintStatusProps) {
  const { t } = useTranslation();

  const determineTagClass = (status: FootprintStatusEnum) => {
    switch (status) {
      case FootprintStatusEnum.PUBLISHED:
        return styles['published-tag'];
      case FootprintStatusEnum.DEPRECATED:
        return styles['deprecated-tag'];
      case FootprintStatusEnum.DRAFT:
        return styles['draft-tag'];
      default:
        return styles['draft-tag'];
    }
  };

  return (
    <Tag rounded className={classNames('text-sm font-medium px-2 py-1', determineTagClass(status))}>
      {t(`productDetailsPage.tabs.pcfTab.pcfStatus.${status}`)}
    </Tag>
  );
}
